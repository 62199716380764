import axios from 'axios'
import React from 'react'
import {Alert, Button, Container, Table} from 'react-bootstrap'
import {createRoot} from 'react-dom/client'

const baseURL = "/api/v1/documents/payments";
const docURL = "/api/v1/document/download";

function Payments() {
    const [payments, setPayments] = React.useState(null)
    React.useEffect(() => {
        axios.get(baseURL).then((response) => {
            setPayments(response.data);
        });
    }, [])

    function handleClick(docId) {
        axios.post(docURL, {'document_id': docId}, {responseType: 'blob'}).then((response) => {
            const blob = new Blob([response.data], {type: response.data.type});
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'unknown';
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
                if (fileNameMatch.length === 2)
                    fileName = fileNameMatch[1];
            }
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        });
    }

    if (!payments) return null;
    return (
        <Container fluid={true}>
            <Alert variant={'success'} className={'mb-3'}>Click the green button for any payment report to
                download</Alert>
            <Table responsive={true} variant={'light'} striped className={'rounded-2 overflow-hidden table-nowrap'}>
                <thead>
                <tr>
                    <th scope={'col'} className={'w-1'}/>
                    <th scope={'col'}>Document Name</th>
                    <th scope={'col'}>Date Added</th>
                </tr>
                </thead>
                <tbody>
                {payments.map(function (payment) {
                    return (
                        <tr key={payment.doc_id} className={'pt-1 pb-1'}>
                            <td data-label={'Download'} className={'text-end pt-1 pb-1'} onClick={() => {
                                handleClick(payment.doc_id);
                            }}>
                                <Button className={'btn-success btn-sm'}>
                                    <i className={'bi bi-file-earmark-arrow-down-fill'}/>
                                </Button>
                                {/*<Button className={'btn btn-sm'}>*/}
                                {/*    <i className="avatar avatar-lg bi bi-file-earmark-arrow-down-fill text-success"/>*/}
                                {/*</Button>*/}
                            </td>
                            <td data-label={'Document Name'} className={'pt-1 pb-1'}>
                                <span className={'pl-5'}>{payment.doc_name}</span>
                            </td>
                            <td data-label={'Date Added'} className={'pt-1 pb-1'}>
                                <span>{payment.doc_created}</span>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>
        </Container>
    )
}

export default Payments

const container = document.getElementById('payments')
if (container) {
    const root = createRoot(container)
    root.render(<Payments/>)
}
