import axios from 'axios'
import React, {useEffect, useMemo, useRef, useState} from 'react'
import {
    Alert,
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    FormLabel,
    FormSelect,
    OverlayTrigger,
    Popover,
    Row,
    Table,
    Toast,
    ToastContainer
} from 'react-bootstrap'
import {ErrorMessage, Field, FieldArray, Formik} from 'formik'
import * as Yup from 'yup'
import {createRoot} from 'react-dom/client'

const programYearsURL = '/api/v1/program-years'
const periodsURL = '/api/v1/periods'
const attendanceEntriesURL = '/api/v1/attendance-entries'
const saveAttendanceEntriesURL = '/api/v1/attendance/process'

function Attendance() {
    const [programYears, setProgramYears] = useState(null)
    const [currentProgramYear, setCurrentProgramYear] = useState(null)
    const [periods, setPeriods] = useState(null)
    const [currentPeriod, setCurrentPeriod] = useState(null)
    const [attendanceEntries, setAttendanceEntries] = useState(null)
    const [readOnly, setReadOnly] = useState(true)
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState(null)
    const [toastVariant, setToastVariant] = useState('success')
    const formikRef = useRef()

    useEffect(() => {
        axios.get(programYearsURL).then((response) => {
            setCurrentProgramYear(response.data.data[0].program_year_id)
            setProgramYears(response.data.data)
            handlePeriods(response.data.data[0].program_year_id)
        })
    }, [])

    const sheet = useMemo(() => attendanceEntries?.sheet, [attendanceEntries])
    const enrollments = useMemo(() => attendanceEntries?.enrollments, [attendanceEntries])
    const initialValues = useMemo(() => {
        const entryValues = enrollments?.reduce((accum, enrollment) => {
            accum[enrollment.entry.id] = {
                identifier: enrollment.entry.id || '',
                enrollment: enrollment.enrollment.id || '',
                daysAttended: enrollment.entry.days_attended_c || '',
                daysScheduled: enrollment.entry.days_scheduled_c || '',
                description: enrollment.entry.description || '',
                receivedOtherTuitionSupport: enrollment.entry.rcvd_other_tuition_support_c || '',
                upkParticipant: enrollment.entry.upk_participant_c || '',
                UpkAndDpp: enrollment.entry.dpp_and_upk_2k_c || '',
                DppFamilyPayingZero: enrollment.entry.is_dpp_family_paying_zero_c || '',
                tuitionCap: enrollment.entry.tuition_cap_c || '',
                earlyEndDate: enrollment.enrollment.earlyEndDate || '',
                lateStartDate: enrollment.enrollment.lateStartDate || '',
                dppScholarshipParticipant: enrollment.enrollment.dppScholarshipStatus || 'Unknown',
                participationType: enrollment.enrollment.participation_type_c || 'Unknown',
                studentName: enrollment.contact.name || 'N/A',
                maxDays: enrollment.rules.maximumAllowedDays,
                lateStartMin: enrollment.rules.lateStartMin,
                lateStartMax: enrollment.rules.lateStartMax,
                earlyEndMin: enrollment.rules.earlyEndMin,
                earlyEndMax: enrollment.rules.earlyEndMax,
            }
            return accum
        }, {})
        return sheet && entryValues && {
            numChildren: sheet.number_of_kids || 0,
            numChildrenDpp: sheet.number_of_dpp_kids || 0,
            sheetNotes: sheet.description,
            sheetId: sheet.id,
            // entries: entryValues
            entries: Object.keys(entryValues).map((k) => entryValues[k])
        }
    }, [enrollments, sheet])

    // const initialValues = useMemo(() => (
    //     sheet && entryValues && {
    //         numChildren: sheet.number_of_kids,
    //         numChildrenDpp: sheet.number_of_dpp_kids,
    //         sheetNotes: sheet.description,
    //         sheetId: sheet.id,
    //         // entries: entryValues
    //         entries: Object.keys(entryValues).map((k) => entryValues[k])
    //     }
    // ), [sheet, entryValues])

    function isDateValid(value) {
        if (!value) {
            return true;
        }
        const date = new Date(value);
        const inputDay = parseInt(value.toISOString().split('T')[0].split('-')[2], 10);
        const parsedDay = date.getDate();

        return inputDay === parsedDay;
    }


    const validateSchema = Yup.object().shape({
        numChildren: Yup.number().min(0, 'Value 0 or greater required').required("Entry required"),
        numChildrenDpp: Yup.number().min(0, 'Value 0 or greater required').required("Entry required"),
        entries: Yup.array()
            .of(
                Yup.object().shape({
                    daysAttended: Yup.number()
                        .min(0, 'Value 0 or greater required')
                        .required("Entry required")
                        .when(['maxDays', 'daysScheduled'], (maxDays, daysScheduled, schema) => {
                            return schema.max(daysScheduled || maxDays, 'Must be less than or equal to Days Scheduled')
                        }),
                    lateStartDate: Yup.date()
                        .nullable()
                        .notRequired()
                        .transform((curr, orig) => orig === '' ? null : curr)
                        .test('valid-date', 'Must be a valid date', value => isDateValid(value))
                        .when(['earlyEndDate', 'lateStartMin', 'lateStartMax'], (earlyEndDate, lateStartMin, lateStartMax, schema) => {
                            if (earlyEndDate) {
                                const eed = new Date(earlyEndDate);
                                if (eed) {
                                    eed.setDate(eed.getDate() - 1);
                                    return schema
                                        .min(lateStartMin, 'Must be on or after ' + lateStartMin.toLocaleDateString())
                                        .max(eed, 'Must be before End Date');
                                }
                            }
                            return schema
                                .min(lateStartMin, 'Must be on or after ' + lateStartMin.toLocaleDateString())
                                .max(lateStartMax, 'Must be no greater than ' + lateStartMax.toLocaleDateString());
                        }),
                    earlyEndDate: Yup.date()
                        .nullable()
                        .notRequired()
                        .transform((curr, orig) => orig === '' ? null : curr)
                        .test('valid-date', 'Must be a valid date', value => isDateValid(value))
                        .when(['lateStartDate', 'earlyEndMin', 'earlyEndMax'], (lateStartDate, earlyEndMin, earlyEndMax, schema) => {
                            if (lateStartDate) {
                                const lsd = new Date(lateStartDate);
                                if (lsd) {
                                    lsd.setDate(lsd.getDate() + 1);
                                    return schema
                                        .min(lsd, 'Must be after Start Date')
                                        .max(earlyEndMax, 'Max date allowed is ' + earlyEndMax.toLocaleDateString());
                                }
                            }
                            return schema
                                .min(earlyEndMin, 'Must be on or after ' + earlyEndMin.toLocaleDateString())
                                .max(earlyEndMax, 'Max date allowed is ' + earlyEndMax.toLocaleDateString());
                        }),
                    daysScheduled: Yup.number()
                        .min(0, 'Value 0 or greater required')
                        .required('Entry required')
                        .when(['maxDays', 'lateStartDate', 'earlyEndDate', 'earlyEndMin', 'lateStartMax'], (maxDays, lateStartDate, earlyEndDate, earlyEndMin, lateStartMax, schema) => {
                            if (lateStartDate && earlyEndDate) {
                                const lsd = new Date(lateStartDate)
                                const eed = new Date(earlyEndDate)
                                const diffInMs = Math.abs(lsd - eed)
                                const diffInDays = (diffInMs / (1000 * 60 * 60 * 24))+1
                                return schema.max(diffInDays.toFixed(0), 'Maximum of ' + diffInDays + ' days')
                            }
                            if (lateStartDate) {
                                const lsd = new Date(lateStartDate)
                                const lsmd = new Date(lateStartMax)
                                const diffInMs = Math.abs(lsd - lsmd)
                                const diffInDays = diffInMs / (1000 * 60 * 60 * 24)+1
                                return schema.max(diffInDays.toFixed(0), 'Maximum of ' + diffInDays + ' days')
                            }
                            if (earlyEndDate) {
                                const eemd = new Date(earlyEndMin)
                                const eed = new Date(earlyEndDate)
                                const diffInMs = Math.abs(eemd - eed)
                                const diffInDays = diffInMs / (1000 * 60 * 60 * 24)+1
                                return schema.max(diffInDays.toFixed(0), 'Maximum of ' + diffInDays + ' days')
                            }
                            return schema.max(maxDays, 'Maximum of ' + maxDays + ' days')
                        }),
                    dppScholarshipParticipant: Yup.string(),
                    receivedOtherTuitionSupport: Yup.mixed().when("dppScholarshipParticipant", {
                        is: (dppScholarshipParticipant) => {
                            return dppScholarshipParticipant === 'Yes'
                        },
                        then: Yup.string().required("Selection required"),
                        otherwise: Yup.string().nullable()
                    }),
                    upkParticipant: Yup.mixed().when("dppScholarshipParticipant", {
                        is: (dppScholarshipParticipant) => {
                            return dppScholarshipParticipant === 'Yes'
                        },
                        then: Yup.string().required("Selection required"),
                        otherwise: Yup.string().nullable()
                    }),
                    tuitionCap: Yup.number(),
                    maxDays: Yup.number(), // use in calcs
                    lateStartMin: Yup.date(), // use in calcs
                    lateStartMax: Yup.date(), // use in calcs
                    earlyEndMin: Yup.date(), // use in calcs
                    earlyEndMax: Yup.date(), // use in calcs
                }, [
                    ['daysScheduled', 'maxDays', 'lateStartDate', 'earlyEndDate', 'earlyEndMin', 'lateStartMax'],
                    ['earlyEndDate', 'lateStartDate', 'earlyEndMin', 'earlyEndMax'],
                    ['lateStartDate', 'earlyEndDate', 'lateStartMin', 'lateStartMax'],
                    ['daysAttended', 'maxDays', 'daysScheduled'],
                ])
            )
    })

    const getPeriodsData = (py) => {
        axios.post(periodsURL, {'program_year_id': py}).then((response) => {
            setCurrentPeriod(response.data.data[0].period_id)
            setPeriods(response.data.data)
            handleAttendanceEntries(py, response.data.data[0].period_id)
        })
    }

    const handlePeriods = (py) => {
        setCurrentProgramYear(py)
        getPeriodsData(py)
    }

    const handleAttendanceEntries = (programYearId, periodId) => {
        axios.post(attendanceEntriesURL, {'program_year_id': programYearId, 'period_id': periodId}).then((response) => {
            setAttendanceEntries(response.data)
            setReadOnly(response.data.sheet.read_only)
        })
    }

    const handleAttendanceSubmit = (fields) => {
        if (formikRef.current.dirty) {
            fields.programYear = currentProgramYear
            fields.period = currentPeriod
            axios.post(saveAttendanceEntriesURL, fields).then((response) => {
                if (response.data.success) {
                    setToastMessage(response.data.message)
                    setToastVariant('success')
                } else {
                    setToastMessage('There were errors! Not sure wht happened here...please screenshot / screen record what caused the errors.')
                    setToastVariant('warning')
                }
                setShowToast(true)
                setAttendanceEntries(response.data.entries)
            })
        } else {
            setToastMessage('No changes to save!')
            setToastVariant('success')
            setShowToast(true)
        }
    }

    const RenderTable = (props) => {
        // console.log('props', props)
        if (readOnly) {
            return <RenderReadOnly {...props} />
        } else {
            return <RenderForm {...props} />
        }
    }

    const RenderReadOnly = (props) => {
        return (
            <>
                <Row className={'text-end'}>
                    <Col xs={12} md={8}>
                        <FormGroup controlId="programYear" className={'d-inline-flex'}>
                            <FormLabel className={'text-nowrap p-2'}>Program Year</FormLabel>
                            <FormSelect
                                size={'sm'}
                                defaultValue={currentProgramYear}
                                onChange={(e) => {
                                    handlePeriods(e.target.value)
                                }}
                                disabled={props.formikRef.current?.dirty}
                            >
                                {programYears.map((programYear) => {
                                    return (
                                        <option key={programYear.program_year_id}
                                                value={programYear.program_year_id}>{programYear.program_year_name}</option>
                                    )
                                })}
                            </FormSelect>
                        </FormGroup>
                    </Col>
                    <Col xs={12} md={4}>
                        <FormGroup controlId="period" className={'d-inline-flex'}>
                            <FormLabel className={'text-nowrap p-2'}>Period</FormLabel>
                            <FormSelect
                                defaultValue={currentPeriod}
                                size={'sm'}
                                onChange={(e) => {
                                    setCurrentPeriod(e.target.value)
                                    handleAttendanceEntries(currentProgramYear, e.target.value)
                                }}
                                disabled={props.formikRef.current?.dirty}
                            >
                                {periods.map((period) => {
                                    return (
                                        <option key={period.period_id}
                                                value={period.period_id}>{period.period_name}</option>
                                    )
                                })}
                            </FormSelect>
                        </FormGroup>
                    </Col>
                </Row>
                <Row className={'m-md-0 pt-2 pb-2'}>
                    <Col
                        className='border border-2 bg-surface-secondary h-full border-dashed rounded-2 d-flex flex-column'>
                        <Row className='text-center'>
                            <Col>
                                <h3>Attendance Metrics <i className="bi bi-lock-fill avatar avatar-lg text-danger"/>
                                </h3>
                            </Col>
                        </Row>
                        <Row className={'text-center'}>
                            <Col><h4>{props.sheet.name}</h4></Col>
                        </Row>
                        <Row className={'border border-2 m-3'} md={3}>
                            <Col sm={12} md={12} lg={12} xl={4} className={'p-2'}>
                                <Row>
                                    <Col xs={6} className={'text-nowrap text-end font-bold'}>
                                        # of Students in DPP classrooms:
                                    </Col>
                                    <Col xs={6} className={'text-nowrap'}>
                                        {props.sheet.number_of_kids || 'N/A'}
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12} md={12} lg={12} xl={4} className={'p-2'}>
                                <Row>
                                    <Col xs={6} className={'text-nowrap text-end font-bold'}>
                                        # of DPP Eligible Students:
                                    </Col>
                                    <Col xs={6} className={'text-nowrap'}>
                                        {props.sheet.number_of_dpp_kids || 'N/A'}
                                    </Col>
                                </Row>
                            </Col>
                            {props.sheet.description &&
                                <Col sm={12} md={12} lg={12} xl={4} className={'p-2'}>
                                    <Row>
                                        <Col xs={6} className={'text-nowrap text-end font-bold'}>
                                            Notes:
                                        </Col>
                                        <Col xs={6}>
                                            {props.sheet.description}
                                        </Col>
                                    </Row>
                                </Col>
                            }
                        </Row>
                    </Col>
                </Row>
                <Table responsive={true} variant={'light'} striped className={'rounded-2 overflow-hidden table-nowrap'}>
                    <thead>
                    <tr>
                        <th scope="col" className={'p-1 text-center'}>Student</th>
                        <th scope="col" className={'p-1 text-center'}>Participation<br/>Type</th>
                        <th scope="col" className={'p-1 text-center'}>Days<br/>Scheduled</th>
                        <th scope="col" className={'p-1 text-center'}>Days<br/>Attended</th>
                        <th scope="col" className={'p-1 text-center'}>
                            Start Date
                            <OverlayTrigger trigger="click" placement="right" overlay={lateStartPopover}>
                                <i className="bi bi-info-square px-2"/>
                            </OverlayTrigger>
                        </th>
                        <th scope="col" className={'p-1 text-center'}>
                            End Date
                            <OverlayTrigger trigger="click" placement="right" overlay={earlyEndPopover}>
                                <i className="bi bi-info-square px-2"/>
                            </OverlayTrigger>
                        </th>
                        <th scope="col" className={'p-1  text-center'}>DPP Scholarship<br/>Participant</th>
                        <th scope="col" className={'p-1  text-center'}>
                            Received Other <br/>Non-UPK Tuition Support?
                        </th>
                        <th scope="col" className={'p-1  text-center'}>
                            UPK Participant?
                        </th>
                        <th scope="col" className={'p-1  text-center'}>
                            UPK + All DPP Funding<br/> Totals $2,000 Or Less?
                            <OverlayTrigger trigger="click" placement="right" overlay={Dpp2kPopup}>
                                <i className="bi bi-info-square px-2"/>
                            </OverlayTrigger>
                        </th>
                        <th scope="col" className={'p-1  text-center'}>
                            Is DPP Family Paying $0?
                        </th>
                        <th scope="col" className={'p-1 text-center'}>Provide a Scholarship<br/> Cap If Required</th>
                        <th scope="col" className={'flex-grow-1 p-1 text-center'}>Notes</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.enrollments.map((attendanceEntry) => {
                        const entry = attendanceEntry.entry
                        const contact = attendanceEntry.contact
                        const enrollment = attendanceEntry.enrollment
                        console.log(entry)
                        return (
                            <tr key={entry.id}>
                                <td data-label="Student">
                                    <i className={'bi bi-person-circle avatar avatar-lg text-primary'}/>
                                    <a className="text-heading font-semibold p-2">
                                        {contact.name || 'N/A'}
                                    </a>
                                </td>
                                <td data-label="Participation Type">
                                    {enrollment.participation_type_c || 'Unknown'}
                                </td>
                                <td data-label="Days Scheduled">
                                    {entry.days_scheduled_c || 'N/A'}
                                </td>
                                <td data-label="Days Attended">
                                    {entry.days_attended_c || 'N/A'}
                                </td>
                                <td data-label="Start Date">
                                    {entry.late_start_date_c || 'N/A'}
                                </td>
                                <td data-label="End Date">
                                    {entry.early_end_date_c || 'N/A'}
                                </td>
                                <td data-label="DPP Scholarship Participant">
                                    <span>{enrollment.dppScholarshipStatus || 'N/A'}</span>
                                </td>
                                <td data-label="Received Other Tuition Support?">
                                    <span>{entry.rcvd_other_tuition_support_c || 'N/A'}</span>
                                </td>
                                <td data-label="UPK Participant?">
                                    <span>{entry.upk_participant_c || 'N/A'}</span>
                                </td>
                                <td data-label="Is DPP and UPK less than $2,000?">
                                    <span>{entry.upk_and_dpp_c || 'N/A'}</span>
                                </td>
                                <td data-label="Is DPP Family Paying $0?">
                                    <span>{entry.is_dpp_family_paying_zero_c || 'N/A'}</span>
                                </td>
                                <td data-label="Tuition Cap" className={'p-1'}>
                                    {entry.tuition_cap_c || 'N/A'}
                                </td>
                                <td data-label="Notes" className="text-end">
                                    {entry.description || 'N/A'}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
            </>
        )
    }

    const lateStartPopover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Start Date</Popover.Header>
            <Popover.Body>
                If child was approved for DPP and/or started attending during this attendance period, please indicate
                the 1st date of DPP approved attendance.
            </Popover.Body>
        </Popover>
    )

    const earlyEndPopover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">End Date</Popover.Header>
            <Popover.Body>
                If this child has left your program, please indicate his or her last day.
            </Popover.Body>
        </Popover>
    )

    const Dpp2kPopup = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">DPP Scholarship Cap</Popover.Header>
            <Popover.Body>
                Children receiving DPP, Scholarship and UPK can layer all three fundings up to $2,000. Schools should indicate a DPP cap for those children if they are layering funds beyond $2,000.
                Without a cap, our scholarship/DPP funds can pay for a child up to $1,810.
            </Popover.Body>
        </Popover>
    )

    const RenderForm = (props) => {
        // console.log('render form')
        if (props.enrollments?.length > 0) {
            return (
                <Formik
                    enableReinitialize={false}
                    initialValues={props.initialValues}
                    validationSchema={props.validateSchema}
                    onSubmit={handleAttendanceSubmit}
                    innerRef={props.formikRef}
                    allowInvalidSubmission={true}
                >
                    {({
                          errors,
                          values,
                          touched,
                          setValues,
                          isValid,
                          dirty,
                          handleChange,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Row className={'text-end'}>
                                <Col xs={12} md={8}>
                                    <FormGroup controlId="programYear" className={'d-inline-flex'}>
                                        <FormLabel className={'text-nowrap p-2'}>Program Year</FormLabel>
                                        <FormSelect
                                            size={'sm'}
                                            defaultValue={currentProgramYear}
                                            onChange={(e) => {
                                                handlePeriods(e.target.value)
                                            }}
                                            disabled={props.formikRef.current?.dirty}
                                        >
                                            {programYears.map((programYear) => {
                                                return (
                                                    <option key={programYear.program_year_id}
                                                            value={programYear.program_year_id}>{programYear.program_year_name}</option>
                                                )
                                            })}
                                        </FormSelect>
                                    </FormGroup>
                                </Col>
                                <Col xs={12} md={4}>
                                    <FormGroup controlId="period" className={'d-inline-flex'}>
                                        <FormLabel className={'text-nowrap p-2'}>Period</FormLabel>
                                        <FormSelect
                                            defaultValue={currentPeriod}
                                            size={'sm'}
                                            onChange={(e) => {
                                                setCurrentPeriod(e.target.value)
                                                handleAttendanceEntries(currentProgramYear, e.target.value)
                                            }}
                                            disabled={props.formikRef.current?.dirty}
                                        >
                                            {periods.map((period) => {
                                                return (
                                                    <option key={period.period_id}
                                                            value={period.period_id}>{period.period_name}</option>
                                                )
                                            })}
                                        </FormSelect>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className={'m-md-1'}>
                                <Col
                                    className='border border-2 bg-surface-secondary h-full border-dashed rounded-3 d-flex flex-column p-5'>
                                    <Row className={'text-center'}>
                                        <Col className={'d-none d-sm-block'}/>
                                        <Col className={'text-nowrap'}>
                                            <h3>
                                                Attendance Metrics <i
                                                className="bi bi-unlock-fill avatar avatar-lg text-success"/>
                                            </h3>
                                        </Col>
                                        <Col className="text-end ">
                                            <div className="mx-n1">
                                                <Button
                                                    className={'btn ' + ((isValid && dirty) ? 'btn-success' : 'btn-primary') + ' d-inline-flex mx-1'}
                                                    onClick={handleSubmit}
                                                    disabled={isSubmitting}
                                                >
                                                    <span className=" pe-2">
                                                        <i className="bi bi-save"/>
                                                    </span>
                                                    <span>{(!isValid && dirty) ? 'Save Progress' : 'Save'}</span>
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='text-center grow'>
                                        <Col className={'d-none'}/>
                                        <Col><h4>{props.sheet.name}</h4></Col>
                                        <Col className={'d-none'}/>
                                    </Row>
                                    <Row
                                        className={'p-2 align-self-center w-md-full w-sm-full w-lg-full w-xl-full w-xxl-1/2'}>
                                        <Col xs={12} md={6} className={'p-2'}>
                                            <FormGroup className={'form-floating mb-1'}>
                                                <Field
                                                    type="number"
                                                    name="numChildren"
                                                    className={'form-control form-control-sm' + (touched.numChildren ? (errors.numChildren ? ' is-invalid' : ' is-valid') : '')}
                                                />
                                                <FormLabel htmlFor={'numChildren'}>
                                                    # of Students in DPP classrooms
                                                </FormLabel>
                                                <ErrorMessage
                                                    name="numChildren"
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} md={6} className={'p-2'}>
                                            <FormGroup className={'form-floating mb-1'}>
                                                <Field
                                                    type="number"
                                                    name="numChildrenDpp"
                                                    className={'form-control form-control-sm' + (touched.numChildrenDpp ? (errors.numChildrenDpp ? ' is-invalid' : ' is-valid') : '')}
                                                />
                                                <FormLabel htmlFor={'numChildrenDpp'}>
                                                    # of DPP Eligible Students
                                                </FormLabel>
                                                <ErrorMessage
                                                    name="numChildrenDpp"
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} className={'p-md-2'}>
                                            <FormGroup className={'form-floating'}>
                                                <Field
                                                    type="textarea"
                                                    name="sheetNotes"
                                                    className={'form-control form-control-sm' + (touched.sheetNotes ? (errors.sheetNotes ? ' is-invalid' : ' is-valid') : '')}
                                                />
                                                <FormLabel htmlFor={'sheetNotes'}>
                                                    Notes
                                                </FormLabel>
                                                <ErrorMessage
                                                    name="sheetNotes"
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={'m-md-1 border border-2 bg-surface-secondary h-full border-dashed rounded-3 d-flex flex-column p-5'}>
                                <Table responsive={true} variant={'light'} striped className={'rounded-2 overflow-hidden table-nowrap'}>
                                <thead>
                                <tr>
                                    <th scope="col" className={'p-1 text-center'}>Student</th>
                                    <th scope="col" className={'p-1 text-center'}>Participation<br/>Type</th>
                                    <th scope="col" className={'p-1 text-center'}>Days<br/>Scheduled</th>
                                    <th scope="col" className={'p-1 text-center'}>Days<br/>Attended</th>
                                    <th scope="col" className={'p-1 text-center'}>
                                        Start Date
                                        <OverlayTrigger trigger="click" placement="right" overlay={lateStartPopover}>
                                            <i className="bi bi-info-square px-2"/>
                                        </OverlayTrigger>
                                    </th>
                                    <th scope="col" className={'p-1 text-center'}>
                                        End Date
                                        <OverlayTrigger trigger="click" placement="right" overlay={earlyEndPopover}>
                                            <i className="bi bi-info-square px-2"/>
                                        </OverlayTrigger>
                                    </th>
                                    <th scope="col" className={'p-1  text-center'}>DPP Scholarship<br/>Participant</th>
                                    <th scope="col" className={'p-1  text-center'}>
                                        Received Other <br/>Non-UPK Tuition Support?
                                    </th>
                                    <th scope="col" className={'p-1  text-center'}>
                                        UPK Participant?
                                    </th>
                                    <th scope="col" className={'p-1  text-center'}>
                                        UPK + All DPP Funding<br/> Totals $2,000 Or Less?
                                        <OverlayTrigger trigger="click" placement="right" overlay={Dpp2kPopup}>
                                            <i className="bi bi-info-square px-2"/>
                                        </OverlayTrigger>
                                    </th>
                                    <th scope="col" className={'p-1  text-center'}>
                                        Is DPP Family Paying $0?
                                    </th>
                                    <th scope="col" className={'p-1 text-center'}>Provide a Scholarship<br/> Cap If Required</th>
                                    <th scope="col" className={'flex-grow-1 p-1 text-center'}>Notes</th>
                                </tr>
                                </thead>
                                <tbody>
                                <FieldArray name="entries">
                                    {({
                                          move,
                                          swap,
                                          push,
                                          insert,
                                          unshift,
                                          pop,
                                          form
                                      }) => (Object.keys(values.entries).map((key, idx) => {

                                        const entryErrors = errors.entries?.length && errors.entries[key] || {}
                                        const entryTouched = touched.entries?.length && touched.entries[key] || {}
                                        // console.log(entryErrors, entryTouched, values)
                                        // console.log(errors)
                                        return (
                                            <tr key={key}>
                                                <td data-label="Student" className={'p-1'}>
                                                    <i className={'bi bi-person-circle avatar avatar-lg text-primary'}/>
                                                    {values.entries[key].studentName}
                                                </td>
                                                <td data-label="Participation Type"
                                                    className={'p-1 text-heading font-semibold'}>
                                                    {values.entries[key].participationType}
                                                </td>
                                                <td data-label='Days Scheduled' className={'p-1'}>
                                                    <Field
                                                        type="number"
                                                        name={`entries.${key}.daysScheduled`}
                                                        max={values.entries[key].maxDays}
                                                        className={'form-control form-control-sm' + (entryTouched.daysScheduled ? (entryErrors.daysScheduled ? ' is-invalid' : ' is-valid') : '')}
                                                    />
                                                    <ErrorMessage
                                                        name={`entries.${key}.daysScheduled`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </td>
                                                <td data-label="Days Attended" className={'p-1'}>
                                                    <Field
                                                        type="number"
                                                        name={`entries.${key}.daysAttended`}
                                                        className={'form-control form-control-sm' + (entryTouched.daysAttended ? (entryErrors.daysAttended ? ' is-invalid' : ' is-valid') : '')}
                                                    />
                                                    <ErrorMessage
                                                        name={`entries.${key}.daysAttended`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </td>
                                                <td data-label="Start Date" className={'p-1'}>
                                                    <Field
                                                        name={`entries.${key}.lateStartDate`}
                                                        type="date"
                                                        min={values.entries[key].lateStartMin}
                                                        max={values.entries[key].lateStartMax}
                                                        className={'form-control form-control-sm' + (entryErrors.lateStartDate && entryTouched.lateStartDate ? ' is-invalid' : '')}
                                                    />
                                                    <ErrorMessage
                                                        name={`entries.${key}.lateStartDate`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </td>
                                                <td data-label="End Date" className={'p-1'}>
                                                    <Field
                                                        name={`entries.${key}.earlyEndDate`}
                                                        type="date"
                                                        min={values.entries[key].earlyEndMin}
                                                        max={values.entries[key].earlyEndMax}
                                                        className={'form-control form-control-sm' + (entryErrors.earlyEndDate && entryTouched.earlyEndDate ? ' is-invalid' : '')}
                                                    />
                                                </td>
                                                <td data-label="DPP Scholarship Participant?"
                                                    className={'p-1 text-center'}>
                                                    {values.entries[key].dppScholarshipParticipant}
                                                </td>
                                                <td data-label="Received Other Tuition Support?" className={'p-1'}>
                                                    <Field
                                                        as={'select'}
                                                        name={`entries.${key}.receivedOtherTuitionSupport`}
                                                        className={'form-select form-select-sm' + (entryTouched.receivedOtherTuitionSupport ? (entryErrors.receivedOtherTuitionSupport ? ' is-invalid' : ' is-valid') : '')}
                                                    >
                                                        <option value={''}/>
                                                        <option value='no'>No</option>
                                                        <option value='yes'>Yes</option>
                                                    </Field>
                                                    <ErrorMessage
                                                        name={`entries.${key}.receivedOtherTuitionSupport`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </td>
                                                <td data-label="UPK Participant?" className={'p-1'}>
                                                    <Field
                                                        as={'select'}
                                                        name={`entries.${key}.upkParticipant`}
                                                        className={'form-select form-select-sm' + (entryTouched.upkParticipant ? (entryErrors.upkParticipant ? ' is-invalid' : ' is-valid') : '')}
                                                    >
                                                        <option value={''}/>
                                                        <option value='no'>No</option>
                                                        <option value='yes'>Yes</option>
                                                    </Field>
                                                    <ErrorMessage
                                                        name={`entries.${key}.upkParticipant`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </td>
                                                <td data-label="Child Receiving Both For $2,000 or less?" className={'p-1'}>
                                                    <Field
                                                        as={'select'}
                                                        name={`entries.${key}.UpkAndDpp`}
                                                        className={'form-select form-select-sm' + (entryTouched.UpkAndDpp ? (entryErrors.UpkAndDpp ? ' is-invalid' : ' is-valid') : '')}
                                                    >
                                                        <option value={''}/>
                                                        <option value='no'>No</option>
                                                        <option value='yes'>Yes</option>
                                                    </Field>
                                                    <ErrorMessage
                                                        name={`entries.${key}.UpkAndDpp`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </td>
                                                <td data-label="Is DPP Family Paying $0?" className={'p-1'}>
                                                    <Field
                                                        as={'select'}
                                                        name={`entries.${key}.DppFamilyPayingZero`}
                                                        className={'form-select form-select-sm' + (entryTouched.DppFamilyPayingZero ? (entryErrors.DppFamilyPayingZero ? ' is-invalid' : ' is-valid') : '')}
                                                    >
                                                        <option value={''}/>
                                                        <option value='no'>No</option>
                                                        <option value='yes'>Yes</option>
                                                    </Field>
                                                    <ErrorMessage
                                                        name={`entries.${key}.DppFamilyPayingZero`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </td>
                                                <td data-label="Tuition Cap" className={'p-1'}>
                                                    <Field
                                                        type="number"
                                                        name={`entries.${key}.tuitionCap`}
                                                        className={'form-control form-control-sm' + (entryTouched.tuitionCap ? (entryErrors.tuitionCap ? ' is-invalid' : ' is-valid') : '')}
                                                    />
                                                    <ErrorMessage
                                                        name={`entries.${key}.tuitionCap`}
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </td>
                                                <td data-label="Notes" className={'text-end p-1'}>
                                                    <Field
                                                        name={`entries.${key}.description`}
                                                        as={'textarea'}
                                                        rows={1}
                                                        className={'form-control form-control-sm' + (entryTouched.description ? (entryErrors.description ? ' is-invalid' : ' is-valid') : '')}
                                                    />
                                                    <ErrorMessage name={`entries.${key}.description`} component="div"
                                                                  className="invalid-feedback"/>
                                                </td>
                                            </tr>
                                        )
                                    }))}
                                </FieldArray>
                                </tbody>
                            </Table>
                            </Row>
                        </Form>
                    )}
                </Formik>

            )
        } else {
            return (
                <Container fluid={true} className={'m-4'}>
                    <Alert variant={'warning'}>
                        There are no enrollments for this period.
                    </Alert>
                </Container>
            )
        }
    }

    if (!programYears) return null
    if (!periods) return null
    if (!attendanceEntries) return null
    // console.log('programYears', programYears)
    // console.log('periods', periods)
    // console.log('attendanceEntries', attendanceEntries)
    // console.log('formikRef', formikRef)
    return (
        <Container fluid={true}>
            <Alert variant={'danger'} className={'mb-2'}>
                NOTICE: PRESS SAVE BEFORE EXITING THIS PAGE OR PROGRESS WILL BE LOST. Entries may be updated until
                locked for payment.
            </Alert>
            <RenderTable sheet={sheet} enrollments={enrollments} initialValues={initialValues}
                         validateSchema={validateSchema} formikRef={formikRef}/>
            <ToastContainer className="p-3" position='bottom-end'>
                <Toast onClose={() => setShowToast(false)} show={showToast} bg={toastVariant} delay={7000} autohide>
                    <Toast.Header closeButton>
                        <strong className="me-auto">System Message</strong>
                    </Toast.Header>
                    <Toast.Body className='text-white'>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </Container>
    )
}

export default Attendance

const container = document.getElementById('attendance')
if (container) {
    const root = createRoot(container)
    root.render(<Attendance/>)
}
