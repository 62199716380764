import {FormGroup, FormLabel} from 'react-bootstrap';
import {ErrorMessage, Field} from 'formik';

const FormField = props => {
    const type = props.type || 'control'
    const name = props.name
    const label = props.label
    const error = props.error
    const touched = props.touched
    const fieldType = props.fieldType || null
    const disabled = props.disabled || undefined
    const readOnly = props.readOnly || undefined
    const min = props.min || undefined
    const max = props.max || undefined
    const fieldStyle = props.fieldStyle || undefined
    const as = props.as || undefined
    const placeholder = props.placeholder || undefined
    const step = props.step || undefined
    const value = props.value || undefined
    const required = props.required || undefined

    return (
        <FormGroup className={'mb-1'} controlId={name}>
            <FormLabel>
                {label}
            </FormLabel>
            <Field
                type={fieldType || undefined}
                name={name}
                {...(min && {min: min})}
                {...(max && {max: max})}
                {...(disabled && {disabled: disabled})}
                className={'form-' + type + ' form-' + type + '-sm' + (touched ? (error ? ' is-invalid' : ' is-valid') : '')}
                style={fieldStyle}
                {...(as && {as: as})}
                {...(step && {step: step})}
                {...(readOnly && {readOnly: readOnly})}
                placeholder={placeholder || (as === 'textarea' ? ('Enter ' + label) : undefined)}
                {...(value && {value: value})}
                {...(required && {required: required})}
            />
            <ErrorMessage
                name={name}
                component={'div'}
                className={'invalid-feedback'}
            />
        </FormGroup>
    );
};

export default FormField
