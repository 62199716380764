import axios from 'axios'
import React, {useState} from 'react'
import FloatingFormField from './partial/FloatingFormField'
import {Button, Col, Container, Form, Row, Toast, ToastContainer} from 'react-bootstrap'
import * as Yup from 'yup'
import {Formik} from 'formik'
import {createRoot} from 'react-dom/client'

const validationSchema = Yup.object().shape({
    infantAvailability: Yup.number().required('Entry required').min(0),
    toddlerAvailability: Yup.number().required('Entry required').min(0),
    preschoolAvailability: Yup.number().required('Entry required').min(0),
    schoolAgeAvailability: Yup.number().required('Entry required').min(0)
})

const availabilityURL = '/api/v1/availability'
const availabilityModifyURL = '/api/v1/availability/update'

function UpdateAvailability() {
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState(null)
    const [toastVariant, setToastVariant] = useState('success')
    const [currentAvailability, setCurrentAvailability] = useState(null)
    React.useEffect(() => {
        axios.get(availabilityURL).then((response) => {
            setCurrentAvailability(response.data.data)
        });
    }, [])

    const handleSubmit = (fields) => {
        axios.post(availabilityModifyURL, fields).then((response) => {
            if (response.data.success) {
                setToastMessage(response.data.message)
                setToastVariant('success')
                setCurrentAvailability(response.data.data)
            } else {
                setToastMessage(response.data.message)
                setToastVariant('warning')
            }
            setShowToast(true)
        })
    }
    if (!currentAvailability) return null;
    return (
        <Container fluid={true}>
            <Row className='m-1'>
                <Col
                    className='border border-2 bg-surface-secondary h-full border-dashed rounded-3 d-flex flex-column p-5'
                >
                    <Row>
                        <Col>
                            <Formik
                                validationSchema={validationSchema}
                                initialValues={{
                                    infantAvailability: currentAvailability.infantAvailability || 0,
                                    toddlerAvailability: currentAvailability.toddlerAvailability || 0,
                                    preschoolAvailability: currentAvailability.preschoolAvailability || 0,
                                    schoolAgeAvailability: currentAvailability.schoolAgeAvailability || 0
                                }}
                                onSubmit={handleSubmit}
                            >
                                {({
                                      errors,
                                      touched,
                                      isValid,
                                      dirty,
                                      handleSubmit,
                                      isSubmitting
                                  }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row className={'m-md-1'}>
                                            <Col
                                                className='h-full d-flex flex-column p-5'>
                                                <Row className={'text-center'}>
                                                    <Col className="text-end ">
                                                        <div className="mx-n1">
                                                            <Button
                                                                className={'btn ' + ((isValid) ? 'btn-success' : 'btn-primary disabled') + ' d-inline-flex mx-1'}
                                                                onClick={handleSubmit}>
                                                                <span className=" pe-2">
                                                                    <i className="bi bi-save"/>
                                                                </span>
                                                                <span>Save</span>
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Container>
                                            <Row className='m-8'>
                                                <Col
                                                    className={'border border-2 bg-surface-secondary h-full border-dashed rounded-3 d-flex flex-column p-5'}
                                                >
                                                    <p className={'mt-3'}>Enter the current number of available spots
                                                        your site has by the age groups listed below. An available spot
                                                        means an empty spot in which you could enroll a new child based
                                                        on their age.
                                                    </p>
                                                    <p className={'mt-3'}>If you have a spot available that can be
                                                        filled with multiple age groups, please enter it into each age
                                                        group. (For example, if you have 1 spot available that can be
                                                        filled with either a toddler or preschooler, you would enter 1
                                                        for Toddler and 1 for Preschool below).
                                                    </p>
                                                    <p className={'mt-3'}>Your entry should include all available
                                                        enrollment spots, including those for non-DPP eligible
                                                        students.
                                                    </p>
                                                    <p className={'mt-3'}>
                                                        Your open spots will be advertised to parents who are searching
                                                        for care in your area. By providing your enrollment availability
                                                        information on a weekly basis, you will ensure parents who are
                                                        looking for care can find you!
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row className='m-4 text-center'>
                                                <div className='text-muted'>
                                                    <i>Last Updated: {currentAvailability.lastUpdated || 'N/A'}</i>
                                                </div>
                                            </Row>
                                            <Row className='m-4'>
                                                <Col xs={12} md={6}>
                                                    <FloatingFormField
                                                        name={'infantAvailability'}
                                                        label={'Infant (0-12 months old)'}
                                                        type={'control'}
                                                        fieldType={'number'}
                                                        min={'0'}
                                                        touched={touched.infantAvailability}
                                                        error={errors.infantAvailability}
                                                    />
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <FloatingFormField
                                                        name={'toddlerAvailability'}
                                                        label={'Toddler (12-36 months old)'}
                                                        type={'control'}
                                                        fieldType={'number'}
                                                        min={'0'}
                                                        touched={touched.toddlerAvailability}
                                                        error={errors.toddlerAvailability}
                                                    />
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <FloatingFormField
                                                        name={'preschoolAvailability'}
                                                        label={'Preschool (3-4 years old)'}
                                                        type={'control'}
                                                        fieldType={'number'}
                                                        min={'0'}
                                                        touched={touched.preschoolAvailability}
                                                        error={errors.preschoolAvailability}
                                                    />
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <FloatingFormField
                                                        name={'schoolAgeAvailability'}
                                                        label={'School Age (5 years old and older)'}
                                                        type={'control'}
                                                        fieldType={'number'}
                                                        min={'0'}
                                                        touched={touched.schoolAgeAvailability}
                                                        error={errors.schoolAgeAvailability}
                                                    />
                                                </Col>
                                            </Row>
                                        </Container>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ToastContainer className="p-3" position='bottom-end'>
                <Toast onClose={() => setShowToast(false)} show={showToast} bg={toastVariant} delay={7000} autohide>
                    <Toast.Header closeButton>
                        <strong className="me-auto">System Message</strong>
                    </Toast.Header>
                    <Toast.Body className='text-white'>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </Container>
    );
}

export default UpdateAvailability

const container = document.getElementById('update-availability')
if (container) {
    const root = createRoot(container)
    root.render(<UpdateAvailability/>)
}
