import axios from 'axios'
import React, {useRef, useState} from 'react'
import FloatingFormField from './partial/FloatingFormField'
import {Button, Col, Form, FormCheck, FormLabel, Row, Tab, Tabs, Toast, ToastContainer} from 'react-bootstrap'
import * as Yup from 'yup'
import {Formik, setNestedObjectValues} from 'formik'
import {createRoot} from 'react-dom/client'
import FormField from "./partial/FormField"

const validationSchema = Yup.object().shape({
    programName: Yup.string().required('Entry required'),
    // programSeason: Yup.string().required('Selection required'),
    operatingHoursSundayStart: Yup.mixed().when("operatingDays", {
        is: (operatingDays) => {
            return operatingDays.includes('0')
        },
        then: Yup.string().required("Selection required"),
        otherwise: Yup.string().nullable()
    }),
    operatingHoursMondayStart: Yup.mixed().when("operatingDays", {
        is: (operatingDays) => {
            return operatingDays.includes('1')
        },
        then: Yup.string().required("Selection required"),
        otherwise: Yup.string().nullable()
    }),
    operatingHoursTuesdayStart: Yup.mixed().when("operatingDays", {
        is: (operatingDays) => {
            return operatingDays.includes('2')
        },
        then: Yup.string().required("Selection required"),
        otherwise: Yup.string().nullable()
    }),
    operatingHoursWednesdayStart: Yup.mixed().when("operatingDays", {
        is: (operatingDays) => {
            return operatingDays.includes('3')
        },
        then: Yup.string().required("Selection required"),
        otherwise: Yup.string().nullable()
    }),
    operatingHoursThursdayStart: Yup.mixed().when("operatingDays", {
        is: (operatingDays) => {
            return operatingDays.includes('4')
        },
        then: Yup.string().required("Selection required"),
        otherwise: Yup.string().nullable()
    }),
    operatingHoursFridayStart: Yup.mixed().when("operatingDays", {
        is: (operatingDays) => {
            return operatingDays.includes('5')
        },
        then: Yup.string().required("Selection required"),
        otherwise: Yup.string().nullable()
    }),
    operatingHoursSaturdayStart: Yup.mixed().when("operatingDays", {
        is: (operatingDays) => {
            return operatingDays.includes('6')
        },
        then: Yup.string().required("Selection required"),
        otherwise: Yup.string().nullable()
    }),
    operatingHoursSundayEnd: Yup.mixed().when("operatingDays", {
        is: (operatingDays) => {
            return operatingDays.includes('0')
        },
        then: Yup.string().required("Selection required"),
        otherwise: Yup.string().nullable()
    }),
    operatingHoursMondayEnd: Yup.mixed().when("operatingDays", {
        is: (operatingDays) => {
            return operatingDays.includes('1')
        },
        then: Yup.string().required("Selection required"),
        otherwise: Yup.string().nullable()
    }),
    operatingHoursTuesdayEnd: Yup.mixed().when("operatingDays", {
        is: (operatingDays) => {
            return operatingDays.includes('2')
        },
        then: Yup.string().required("Selection required"),
        otherwise: Yup.string().nullable()
    }),
    operatingHoursWednesdayEnd: Yup.mixed().when("operatingDays", {
        is: (operatingDays) => {
            return operatingDays.includes('3')
        },
        then: Yup.string().required("Selection required"),
        otherwise: Yup.string().nullable()
    }),
    operatingHoursThursdayEnd: Yup.mixed().when("operatingDays", {
        is: (operatingDays) => {
            return operatingDays.includes('4')
        },
        then: Yup.string().required("Selection required"),
        otherwise: Yup.string().nullable()
    }),
    operatingHoursFridayEnd: Yup.mixed().when("operatingDays", {
        is: (operatingDays) => {
            return operatingDays.includes('5')
        },
        then: Yup.string().required("Selection required"),
        otherwise: Yup.string().nullable()
    }),
    operatingHoursSaturdayEnd: Yup.mixed().when("operatingDays", {
        is: (operatingDays) => {
            return operatingDays.includes('6')
        },
        then: Yup.string().required("Selection required"),
        otherwise: Yup.string().nullable()
    }),
})

const providerProfileURL = '/api/v1/provider-profile'
const providerProfileModifyURL = '/api/v1/provider-profile/update'

function ProviderProfile() {
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState(null)
    const [toastVariant, setToastVariant] = useState('success')
    const [currentProviderProfile, setCurrentProviderProfile] = useState(null)
    const [activeTab, setActiveTab] = useState('instructions')
    const [hoursEnabled, setHoursEnabled] = useState(false)
    const [confirmEnabled, setConfirmEnabled] = useState(false)
    const [editing, setEditing] = useState(false)
    const [showTimes0, setShowTimes0] = useState(false)
    const [showTimes1, setShowTimes1] = useState(false)
    const [showTimes2, setShowTimes2] = useState(false)
    const [showTimes3, setShowTimes3] = useState(false)
    const [showTimes4, setShowTimes4] = useState(false)
    const [showTimes5, setShowTimes5] = useState(false)
    const [showTimes6, setShowTimes6] = useState(false)
    React.useEffect(() => {
        axios.get(providerProfileURL).then((response) => {
            setCurrentProviderProfile(response.data.data)
            response.data.data.operatingDays.forEach(
                (element) => {
                    switch (element) {
                        case '0':
                            setShowTimes0(true)
                            break
                        case '1':
                            setShowTimes1(true)
                            break
                        case '2':
                            setShowTimes2(true)
                            break
                        case '3':
                            setShowTimes3(true)
                            break
                        case '4':
                            setShowTimes4(true)
                            break
                        case '5':
                            setShowTimes5(true)
                            break
                        case '6':
                            setShowTimes6(true)
                            break
                    }
                }
            )
        });
    }, [])
    const formikRef = useRef(null)

    const handleSubmit = (fields) => {
        axios.post(providerProfileModifyURL, fields).then((response) => {
            if (response.data.success) {
                setToastMessage(response.data.message)
                setToastVariant('success')
                setCurrentProviderProfile(response.data.data)
            } else {
                setToastMessage(response.data.message)
                setToastVariant('warning')
            }
            setShowToast(true)
            setEditing(false)
        })
    }

    const handleConfirm = () => {
        axios.post(providerProfileModifyURL, currentProviderProfile).then((response) => {
            if (response.data.success) {
                setToastMessage('Successfully confirmed provider profile.')
                setToastVariant('success')
                setCurrentProviderProfile(response.data.data)
            } else {
                setToastMessage(response.data.message)
                setToastVariant('warning')
            }
            setShowToast(true)
            setEditing(false)
        })
    }

    const nextStep = () => {
        switch (activeTab) {
            case 'instructions':
                setActiveTab('general')
                break;
            case 'general':
                setActiveTab('hours')
                setHoursEnabled(true)
                break;
            case 'hours':
                setActiveTab('confirm')
                setConfirmEnabled(true)
                break;
            case 'confirm':
                break;
        }
    }

    const previousStep = () => {
        switch (activeTab) {
            case 'instructions':
                break;
            case 'general':
                setActiveTab('instructions')
                break;
            case 'hours':
                setActiveTab('general')
                break;
            case 'confirm':
                setActiveTab('hours')
                break;
        }
    }


    const ShowView = (props) => {
        // const {initialValues, validateSchema, ...modalProps} = props;
        return (
            <div>
                <Row className='m-1'>
                    <Col
                        className='border border-2 bg-surface-secondary h-full border-dashed rounded-3 d-flex flex-column p-5'
                    >
                        <Row>
                            <Col>
                                <Row className='m-4 text-center'>
                                    <div className='text-muted'>
                                        <i>Last Updated: {currentProviderProfile.lastUpdated || 'N/A'}</i>
                                    </div>
                                </Row>
                                <fieldset className={'row m-4'}>
                                    <legend>General</legend>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>License Name</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.licenseName || 'N/A'}
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>License Number</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.licenseNumber || 'N/A'}
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>Program Name</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.programName || 'N/A'}
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>License Type</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.licenseType || 'N/A'}
                                    </Col>
                                    <Col xs={12} md={3} className={'p-2'}>
                                        <FormLabel>Program Description</FormLabel>
                                    </Col>
                                    <Col xs={12} md={9} className={'p-2'}>
                                        {currentProviderProfile.programDescription || 'N/A'}
                                    </Col>
                                    <Col xs={12} md={3} className={'p-2'}>
                                        <FormLabel>Address</FormLabel>
                                    </Col>
                                    <Col xs={12} md={9} className={'p-2'}>
                                        {currentProviderProfile.address || 'N/A'}
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>Contact First Name</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.contactFirstName || 'N/A'}
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>Contact Last Name</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.contactLastName || 'N/A'}
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>Contact Phone Number</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.contactPhone || 'N/A'}
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>Contact Email</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.contactEmail || 'N/A'}
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>Director Name</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.directorName || 'N/A'}
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>School Website</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.website || 'N/A'}
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>Participation Levels</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.participationLevels || 'N/A'}
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>Ages Served</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.agesServed || 'N/A'}
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>Quality Rating Finder</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.qualityRating || 'N/A'}
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>Quality Rating (Tuition Credit)</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.qualityRatingTuitionCredit || 'N/A'}
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>Financial Assistance Available</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.financialAssistanceAvailable || 'N/A'}
                                    </Col>
                                    <Col xs={12} md={6} className={'p-2'}/>
                                    <Col xs={12} md={6} className={'p-2'}>
                                        <Row>
                                            <FormLabel htmlFor={'curriculum'}>Curriculum:</FormLabel>
                                            {Object.keys(currentProviderProfile.options.curriculum).map((curricula) => {
                                                return (
                                                    <Col xs={12} md={6} key={'curriculum-col-' + curricula}>
                                                        <FormCheck
                                                            type='switch'
                                                            name='curriculum'
                                                            label={currentProviderProfile.options.curriculum[curricula]}
                                                            defaultChecked={currentProviderProfile.curriculum.includes(curricula)}
                                                            value={curricula}
                                                            key={'curriculum-' + curricula}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </Col>
                                    <Col xs={12} md={6} className={'p-2'}>
                                        <Row>
                                            <FormLabel htmlFor={'languagesSpoken'}>
                                                Languages Spoken:
                                            </FormLabel>
                                            {Object.keys(currentProviderProfile.options.languagesSpoken).map((lang) => {
                                                return (
                                                    <Col xs={12} md={6} key={'languagesSpoken-col-' + lang}>
                                                        <FormCheck
                                                            type='switch'
                                                            name='languagesSpoken'
                                                            label={currentProviderProfile.options.languagesSpoken[lang]}
                                                            defaultChecked={currentProviderProfile.languagesSpoken.includes(lang)}
                                                            value={lang}
                                                            key={'languagesSpoken-' + lang}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Col>
                                                )
                                            })}
                                        </Row>
                                    </Col>
                                </fieldset>
                                <fieldset className={'row m-4'} role="group">
                                    <legend>Operating Schedule</legend>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        <FormLabel>Program Season</FormLabel>
                                    </Col>
                                    <Col xs={6} md={3} className={'p-2'}>
                                        {currentProviderProfile.options.programSeason[currentProviderProfile.programSeason] || 'N/A'}
                                    </Col>
                                    <Row key={'od-row-0'} className={'mb-3 p-3'}>
                                        <Col xs={12} md={4} key={'od-switch-0'}>
                                            <FormCheck
                                                type='switch'
                                                name='operatingDays'
                                                label={'Sunday'}
                                                defaultChecked={currentProviderProfile.operatingDays.includes('0')}
                                                value={'0'}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <Col
                                            xs={6}
                                            md={4}
                                            key={'od-hour-start-0'}
                                            hidden={!currentProviderProfile.operatingDays.includes('0')}
                                        >
                                            {currentProviderProfile.operatingHoursSundayStart || 'N/A'}
                                        </Col>
                                        <Col
                                            xs={6}
                                            md={4}
                                            key={'od-hour-end-0'}
                                            hidden={!currentProviderProfile.operatingDays.includes('0')}
                                        >
                                            {currentProviderProfile.operatingHoursSundayEnd || 'N/A'}
                                        </Col>
                                    </Row>
                                    <Row key={'od-row-1'} className={'bg-white mb-3 p-3'}>
                                        <Col xs={12} md={4} key={'od-switch-1'}>
                                            <FormCheck
                                                type='switch'
                                                name='operatingDays'
                                                label={'Monday'}
                                                defaultChecked={currentProviderProfile.operatingDays.includes('1')}
                                                value={'1'}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <Col
                                            xs={6}
                                            md={4}
                                            key={'od-hour-start-1'}
                                            hidden={!currentProviderProfile.operatingDays.includes('1')}
                                        >
                                            {currentProviderProfile.operatingHoursMondayStart || 'N/A'}
                                        </Col>
                                        <Col
                                            xs={6}
                                            md={4}
                                            key={'od-hour-end-1'}
                                            hidden={!currentProviderProfile.operatingDays.includes('1')}
                                        >
                                            {currentProviderProfile.operatingHoursMondayEnd || 'N/A'}
                                        </Col>
                                    </Row>
                                    <Row key={'od-row-2'} className={'mb-3 p-3'}>
                                        <Col xs={12} md={4} key={'od-switch-2'}>
                                            <FormCheck
                                                type='switch'
                                                name='operatingDays'
                                                label={'Tuesday'}
                                                defaultChecked={currentProviderProfile.operatingDays.includes('2')}
                                                value={'2'}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <Col
                                            xs={6}
                                            md={4}
                                            key={'od-hour-start-2'}
                                            hidden={!currentProviderProfile.operatingDays.includes('2')}
                                        >
                                            {currentProviderProfile.operatingHoursTuesdayStart || 'N/A'}
                                        </Col>
                                        <Col
                                            xs={6}
                                            md={4}
                                            key={'od-hour-end-2'}
                                            hidden={!currentProviderProfile.operatingDays.includes('2')}
                                        >
                                            {currentProviderProfile.operatingHoursTuesdayEnd || 'N/A'}
                                        </Col>
                                    </Row>
                                    <Row key={'od-row-3'} className={'bg-white mb-3 p-3'}>
                                        <Col xs={12} md={4} key={'od-switch-3'}>
                                            <FormCheck
                                                type='switch'
                                                name='operatingDays'
                                                label={'Wednesday'}
                                                defaultChecked={currentProviderProfile.operatingDays.includes('3')}
                                                value={'3'}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <Col
                                            xs={6}
                                            md={4}
                                            key={'od-hour-start-3'}
                                            hidden={!currentProviderProfile.operatingDays.includes('3')}
                                        >
                                            {currentProviderProfile.operatingHoursWednesdayStart || 'N/A'}
                                        </Col>
                                        <Col
                                            xs={6}
                                            md={4}
                                            key={'od-hour-end-3'}
                                            hidden={!currentProviderProfile.operatingDays.includes('3')}
                                        >
                                            {currentProviderProfile.operatingHoursWednesdayEnd || 'N/A'}
                                        </Col>
                                    </Row>
                                    <Row key={'od-row-4'} className={'mb-3 p-3'}>
                                        <Col xs={12} md={4} key={'od-switch-4'}>
                                            <FormCheck
                                                type='switch'
                                                name='operatingDays'
                                                label={'Thursday'}
                                                defaultChecked={currentProviderProfile.operatingDays.includes('4')}
                                                value={'4'}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <Col
                                            xs={6}
                                            md={4}
                                            key={'od-hour-start-4'}
                                            hidden={!currentProviderProfile.operatingDays.includes('4')}
                                        >
                                            {currentProviderProfile.operatingHoursThursdayStart || 'N/A'}
                                        </Col>
                                        <Col
                                            xs={6}
                                            md={4}
                                            key={'od-hour-end-4'}
                                            hidden={!currentProviderProfile.operatingDays.includes('4')}
                                        >
                                            {currentProviderProfile.operatingHoursThursdayEnd || 'N/A'}
                                        </Col>
                                    </Row>
                                    <Row key={'od-row-5'} className={'bg-white mb-3 p-3'}>
                                        <Col xs={12} md={4} key={'od-switch-5'}>
                                            <FormCheck
                                                type='switch'
                                                name='operatingDays'
                                                label={'Friday'}
                                                defaultChecked={currentProviderProfile.operatingDays.includes('5')}
                                                value={'5'}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <Col
                                            xs={6}
                                            md={4}
                                            key={'od-hour-start-5'}
                                            hidden={!currentProviderProfile.operatingDays.includes('5')}
                                        >
                                            {currentProviderProfile.operatingHoursFridayStart || 'N/A'}
                                        </Col>
                                        <Col
                                            xs={6}
                                            md={4}
                                            key={'od-hour-end-5'}
                                            hidden={!currentProviderProfile.operatingDays.includes('5')}
                                        >
                                            {currentProviderProfile.operatingHoursFridayEnd || 'N/A'}
                                        </Col>
                                    </Row>
                                    <Row key={'od-row-6'} className={'mb-3 p-3'}>
                                        <Col xs={12} md={4} key={'od-switch-6'}>
                                            <FormCheck
                                                type='switch'
                                                name='operatingDays'
                                                label={'Saturday'}
                                                defaultChecked={currentProviderProfile.operatingDays.includes('6')}
                                                value={'6'}
                                                readOnly
                                                disabled
                                            />
                                        </Col>
                                        <Col
                                            xs={6}
                                            md={4}
                                            key={'od-hour-start-6'}
                                            hidden={!currentProviderProfile.operatingDays.includes('6')}
                                        >
                                            {currentProviderProfile.operatingHoursSaturdayStart || 'N/A'}
                                        </Col>
                                        <Col
                                            xs={6}
                                            md={4}
                                            key={'od-hour-end-6'}
                                            hidden={!currentProviderProfile.operatingDays.includes('6')}
                                        >
                                            {currentProviderProfile.operatingHoursSaturdayEnd || 'N/A'}
                                        </Col>
                                    </Row>
                                </fieldset>
                                <Row className={'m-md-1'}>
                                    <Col
                                        className='h-full d-flex flex-column p-5'>
                                        <Row className={'text-center'}>
                                            <Col
                                                className={'text-end'}>
                                                <Button
                                                    variant={'primary'}
                                                    className={'d-inline-flex'}
                                                    onClick={() => {
                                                        setEditing(true)
                                                    }}
                                                >
                                                            <span>
                                                                Update My Provider Profile
                                                            </span>
                                                    <span className='ps-2'>
                                                                <i className='bi bi-arrow-right'/>
                                                            </span>
                                                </Button>
                                            </Col>
                                            <Col
                                                className={'text-end'}>
                                                <Button
                                                    type={'submit'}
                                                    variant={'success'}
                                                    className={'d-inline-flex'}
                                                    onClick={handleConfirm}
                                                >
                                                            <span className='pe-2'>
                                                                <i className={'bi bi-check'}/>
                                                            </span>
                                                    <span>Confirm My Provider Profile</span>
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <ToastContainer className='p-3' position='bottom-end'>
                    <Toast onClose={() => setShowToast(false)} show={showToast} bg={toastVariant} delay={7000} autohide>
                        <Toast.Header closeButton>
                            <strong className='me-auto'>System Message</strong>
                        </Toast.Header>
                        <Toast.Body className='text-white'>{toastMessage}</Toast.Body>
                    </Toast>
                </ToastContainer>
            </div>
        );
    }

    if (!currentProviderProfile) return null;
    if (!editing) return <ShowView/>
    // console.log(formikRef)
    return (
        <div>
            <Row className='m-1'>
                <Col
                    className='border border-2 bg-surface-secondary h-full border-dashed rounded-3 d-flex flex-column p-5'
                >
                    <Row>
                        <Col>
                            <Formik
                                validationSchema={validationSchema}
                                // initialValues={{
                                //     infantAvailability: currentProviderProfile.infantAvailability || 0,
                                //     toddlerAvailability: currentProviderProfile.toddlerAvailability || 0,
                                //     preschoolAvailability: currentProviderProfile.preschoolAvailability || 0,
                                //     schoolAgeAvailability: currentProviderProfile.schoolAgeAvailability || 0,
                                //     licenseName: currentProviderProfile.licenseName,
                                //     programName: currentProviderProfile.programName,
                                //     programDescription: currentProviderProfile.programDescription,
                                //     licenseNumber: currentProviderProfile.licenseNumber,
                                //     programType: currentProviderProfile.programType,
                                //     operatingDays: currentProviderProfile.operatingDays,
                                //     operatingHoursSundayStart: currentProviderProfile.operatingHoursSundayStart,
                                //     operatingHoursMondayStart: currentProviderProfile.operatingHoursMondayStart,
                                //     operatingHoursTuesdayStart: currentProviderProfile.operatingHoursTuesdayStart,
                                //     operatingHoursWednesdayStart: currentProviderProfile.operatingHoursWednesdayStart,
                                //     operatingHoursThursdayStart: currentProviderProfile.operatingHoursThursdayStart,
                                //     operatingHoursFridayStart: currentProviderProfile.operatingHoursFridayStart,
                                //     operatingHoursSaturdayStart: currentProviderProfile.operatingHoursSaturdayStart,
                                //     operatingHoursSundayEnd: currentProviderProfile.operatingHoursSundayEnd,
                                //     operatingHoursMondayEnd: currentProviderProfile.operatingHoursMondayEnd,
                                //     operatingHoursTuesdayEnd: currentProviderProfile.operatingHoursTuesdayEnd,
                                //     operatingHoursWednesdayEnd: currentProviderProfile.operatingHoursWednesdayEnd,
                                //     operatingHoursThursdayEnd: currentProviderProfile.operatingHoursThursdayEnd,
                                //     operatingHoursFridayEnd: currentProviderProfile.operatingHoursFridayEnd,
                                //     operatingHoursSaturdayEnd: currentProviderProfile.operatingHoursSaturdayEnd,
                                // }}
                                initialValues={currentProviderProfile}
                                onSubmit={handleSubmit}
                                innerRef={formikRef}
                            >
                                {({
                                      errors,
                                      touched,
                                      isValid,
                                      dirty,
                                      handleSubmit,
                                      handleChange,
                                      isSubmitting,
                                      values,
                                      validateForm,
                                      isInitialValid,
                                      setTouched
                                  }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Row className='m-4 text-center'>
                                            <div className='text-muted'>
                                                <i>Last Updated: {currentProviderProfile.lastUpdated || 'N/A'}</i>
                                            </div>
                                        </Row>
                                        <Tabs
                                            activeKey={activeTab}
                                            onSelect={(k) => setActiveTab(k)}
                                            className='mb-3'
                                        >
                                            <Tab eventKey='instructions' title='Instructions'>
                                                <Row className='m-8'>
                                                    <Col
                                                        className={'border border-2 bg-surface-secondary h-full border-dashed rounded-3 d-flex flex-column p-5'}
                                                    >
                                                        <p className={'mt-3'}>
                                                            Enter the current number of available spots your site has by
                                                            the age groups listed below. An available spot means an
                                                            empty spot in which you could enroll a new child based on
                                                            their age.
                                                        </p>
                                                        <p className={'mt-3'}>
                                                            If you have a spot available that can be filled with
                                                            multiple age groups, please enter it into each age group.
                                                            (For example, if you have 1 spot available that can be
                                                            filled with either a toddler or preschooler, you would enter
                                                            1 for Toddler and 1 for Preschool below).
                                                        </p>
                                                        <p className={'mt-3'}>
                                                            Your entry should include all available enrollment spots,
                                                            including those for non-DPP eligible students.
                                                        </p>
                                                        <p className={'mt-3'}>
                                                            Your open spots will be advertised to parents who are
                                                            searching for care in your area. By providing your
                                                            enrollment availability information on a weekly basis, you
                                                            will ensure parents who are looking for care can find you!
                                                        </p>
                                                    </Col>
                                                </Row>
                                            </Tab>
                                            <Tab eventKey='general' title='General'>
                                                <fieldset className={'row m-4'}>
                                                    <legend>General</legend>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'licenseName'}
                                                            label={'License Name'}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'licenseNumber'}
                                                            label={'License Number'}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'programName'}
                                                            label={'Program Name'}
                                                            touched={touched.programName}
                                                            error={errors.programName}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'licenseType'}
                                                            label={'License Type'}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col xs={12}>
                                                        <FloatingFormField
                                                            name={'programDescription'}
                                                            label={'Program Description'}
                                                            as={'textarea'}
                                                            touched={touched.programDescription}
                                                            error={errors.programDescription}
                                                            fieldStyle={{height: '100px'}}
                                                        />
                                                    </Col>
                                                    <Col xs={12}>
                                                        <FloatingFormField
                                                            name={'address'}
                                                            label={'Address'}
                                                            as={'textarea'}
                                                            readOnly
                                                            disabled
                                                            value={currentProviderProfile.address}
                                                            fieldStyle={{height: '100px'}}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'contactFirstName'}
                                                            label={'Contact First Name'}
                                                            touched={touched.contactFirstName}
                                                            error={errors.contactFirstName}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'contactLastName'}
                                                            label={'Contact Last Name'}
                                                            touched={touched.contactLastName}
                                                            error={errors.contactLastName}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'contactPhone'}
                                                            label={'Contact Phone Number'}
                                                            touched={touched.contactPhone}
                                                            error={errors.contactPhone}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'contactEmail'}
                                                            label={'Contact Email'}
                                                            touched={touched.contactEmail}
                                                            error={errors.contactEmail}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'directorName'}
                                                            label={'Director Name'}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'website'}
                                                            label={'School Website'}
                                                            touched={touched.website}
                                                            error={errors.website}
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'participationLevels'}
                                                            label={'Participation Levels'}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'agesServed'}
                                                            label={'Ages Served'}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'qualityRating'}
                                                            label={'Quality Rating Finder'}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'qualityRatingTuitionCredit'}
                                                            label={'Quality Rating (Tuition Credit)'}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <FloatingFormField
                                                            name={'financialAssistanceAvailable'}
                                                            label={'Financial Assistance Available'}
                                                            readOnly
                                                            disabled
                                                        />
                                                    </Col>
                                                    {/*<Col xs={12} md={12}>*/}
                                                    {/*    <FloatingFormField*/}
                                                    {/*        name={'curriculum'}*/}
                                                    {/*        label={'Curriculum'}*/}
                                                    {/*        type={'select'}*/}
                                                    {/*        as={'select'}*/}
                                                    {/*        touched={touched.curriculum}*/}
                                                    {/*        error={errors.curriculum}*/}
                                                    {/*        multiple*/}
                                                    {/*        fieldStyle={{height: '100px'}}*/}
                                                    {/*    >*/}
                                                    {/*        {Object.keys(currentProviderProfile.options.curriculum).map((curricula) => {*/}
                                                    {/*            return (*/}
                                                    {/*                <option key={'curriculum-'+curricula}*/}
                                                    {/*                        value={curricula}>{currentProviderProfile.options.curriculum[curricula]}</option>*/}
                                                    {/*            )*/}
                                                    {/*        })}*/}
                                                    {/*    </FloatingFormField>*/}
                                                    {/*</Col>*/}
                                                    <Col xs={12} md={6}/>
                                                    <Col xs={12} md={6}>
                                                        <Row>
                                                            <FormLabel htmlFor={'curriculum'}>Curriculum:</FormLabel>
                                                            {Object.keys(currentProviderProfile.options.curriculum).map((curricula) => {
                                                                return (
                                                                    <Col xs={12} md={6}
                                                                         key={'curriculum-col-' + curricula}>
                                                                        <FormCheck
                                                                            type='switch'
                                                                            name='curriculum'
                                                                            label={currentProviderProfile.options.curriculum[curricula]}
                                                                            onChange={handleChange}
                                                                            defaultChecked={currentProviderProfile.curriculum.includes(curricula)}
                                                                            value={curricula}
                                                                            key={'curriculum-' + curricula}
                                                                            // className={'form-check-inline'}
                                                                        />
                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} md={6}>
                                                        <Row>
                                                            <FormLabel htmlFor={'languagesSpoken'}>
                                                                Languages Spoken:
                                                            </FormLabel>
                                                            {Object.keys(currentProviderProfile.options.languagesSpoken).map((lang) => {
                                                                return (
                                                                    <Col xs={12} md={6}
                                                                         key={'languagesSpoken-col-' + lang}>
                                                                        <FormCheck
                                                                            type='switch'
                                                                            name='languagesSpoken'
                                                                            label={currentProviderProfile.options.languagesSpoken[lang]}
                                                                            onChange={handleChange}
                                                                            defaultChecked={currentProviderProfile.languagesSpoken.includes(lang)}
                                                                            value={lang}
                                                                            key={'languagesSpoken-' + lang}
                                                                            // className={'form-check-inline'}
                                                                        />
                                                                    </Col>
                                                                )
                                                            })}
                                                        </Row>
                                                    </Col>
                                                </fieldset>
                                            </Tab>
                                            <Tab eventKey='hours' title='Operating Schedule'
                                                 disabled={!hoursEnabled}>
                                                <fieldset className={'row m-4'} role="group">
                                                    <legend>Operating Schedule</legend>
                                                    <Col xs={12} md={12}>
                                                        <FloatingFormField
                                                            name={'programSeason'}
                                                            label={'Program Season'}
                                                            type={'select'}
                                                            as={'select'}
                                                            touched={touched.programSeason}
                                                            error={errors.programSeason}
                                                        >
                                                            {Object.keys(currentProviderProfile.options.programSeason).map((season) => {
                                                                return (
                                                                    <option key={'programSeason-' + season}
                                                                            value={season}>{currentProviderProfile.options.programSeason[season]}</option>
                                                                )
                                                            })}
                                                        </FloatingFormField>
                                                    </Col>
                                                    <Row key={'od-row-0'} className={'mb-3 p-3'}>
                                                        <Col xs={12} md={4} key={'od-switch-0'}>
                                                            <FormCheck
                                                                type='switch'
                                                                name='operatingDays'
                                                                label={'Sunday'}
                                                                onChange={(event) => {
                                                                    setShowTimes0(event.target.checked)
                                                                    handleChange(event)
                                                                }}
                                                                defaultChecked={currentProviderProfile.operatingDays.includes('0')}
                                                                value={'0'}
                                                            />
                                                        </Col>
                                                        <Col xs={6} md={4} key={'od-hour-start-0'}
                                                             id={'od-hour-start-0'} hidden={!showTimes0}>
                                                            <FormField
                                                                name={'operatingHoursSundayStart'}
                                                                label={'Start Time'}
                                                                fieldType={'time'}
                                                                touched={touched.operatingHoursSundayStart}
                                                                error={errors.operatingHoursSundayStart}
                                                            />
                                                        </Col>
                                                        <Col xs={6} md={4} key={'od-hour-end-0'} id={'od-hour-end-0'}
                                                             hidden={!showTimes0}>
                                                            <FormField
                                                                name={'operatingHoursSundayEnd'}
                                                                label={'End Time'}
                                                                fieldType={'time'}
                                                                touched={touched.operatingHoursSundayEnd}
                                                                error={errors.operatingHoursSundayEnd}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row key={'od-row-1'} className={'bg-white mb-3 p-3'}>
                                                        <Col xs={12} md={4} key={'od-switch-1'}>
                                                            <FormCheck
                                                                type='switch'
                                                                name='operatingDays'
                                                                label={'Monday'}
                                                                onChange={(event) => {
                                                                    setShowTimes1(event.target.checked)
                                                                    handleChange(event)
                                                                }}
                                                                defaultChecked={currentProviderProfile.operatingDays.includes('1')}
                                                                value={'1'}
                                                            />
                                                        </Col>
                                                        <Col xs={6} md={4} key={'od-hour-start-1'}
                                                             id={'od-hour-start-1'} hidden={!showTimes1}>
                                                            <FormField
                                                                name={'operatingHoursMondayStart'}
                                                                label={'Start Time'}
                                                                fieldType={'time'}
                                                                touched={touched.operatingHoursMondayStart}
                                                                error={errors.operatingHoursMondayStart}
                                                            />
                                                        </Col>
                                                        <Col xs={6} md={4} key={'od-hour-end-1'} id={'od-hour-end-1'}
                                                             hidden={!showTimes1}>
                                                            <FormField
                                                                name={'operatingHoursMondayEnd'}
                                                                label={'End Time'}
                                                                fieldType={'time'}
                                                                touched={touched.operatingHoursMondayEnd}
                                                                error={errors.operatingHoursMondayEnd}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row key={'od-row-2'} className={'mb-3 p-3'}>
                                                        <Col xs={12} md={4} key={'od-switch-2'}>
                                                            <FormCheck
                                                                type='switch'
                                                                name='operatingDays'
                                                                label={'Tuesday'}
                                                                onChange={(event) => {
                                                                    setShowTimes2(event.target.checked)
                                                                    handleChange(event)
                                                                }}
                                                                defaultChecked={currentProviderProfile.operatingDays.includes('2')}
                                                                value={'2'}
                                                            />
                                                        </Col>
                                                        <Col xs={6} md={4} key={'od-hour-start-2'}
                                                             id={'od-hour-start-2'} hidden={!showTimes2}>
                                                            <FormField
                                                                name={'operatingHoursTuesdayStart'}
                                                                label={'Start Time'}
                                                                fieldType={'time'}
                                                                touched={touched.operatingHoursTuesdayStart}
                                                                error={errors.operatingHoursTuesdayStart}
                                                            />
                                                        </Col>
                                                        <Col xs={6} md={4} key={'od-hour-end-2'} id={'od-hour-end-2'}
                                                             hidden={!showTimes2}>
                                                            <FormField
                                                                name={'operatingHoursTuesdayEnd'}
                                                                label={'End Time'}
                                                                fieldType={'time'}
                                                                touched={touched.operatingHoursTuesdayEnd}
                                                                error={errors.operatingHoursTuesdayEnd}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row key={'od-row-3'} className={'bg-white mb-3 p-3'}>
                                                        <Col xs={12} md={4} key={'od-switch-3'}>
                                                            <FormCheck
                                                                type='switch'
                                                                name='operatingDays'
                                                                label={'Wednesday'}
                                                                onChange={(event) => {
                                                                    setShowTimes3(event.target.checked)
                                                                    handleChange(event)
                                                                }}
                                                                defaultChecked={currentProviderProfile.operatingDays.includes('3')}
                                                                value={'3'}
                                                            />
                                                        </Col>
                                                        <Col xs={6} md={4} key={'od-hour-start-3'}
                                                             id={'od-hour-start-3'} hidden={!showTimes3}>
                                                            <FormField
                                                                name={'operatingHoursWednesdayStart'}
                                                                label={'Start Time'}
                                                                fieldType={'time'}
                                                                touched={touched.operatingHoursWednesdayStart}
                                                                error={errors.operatingHoursWednesdayStart}
                                                            />
                                                        </Col>
                                                        <Col xs={6} md={4} key={'od-hour-end-3'} id={'od-hour-end-3'}
                                                             hidden={!showTimes3}>
                                                            <FormField
                                                                name={'operatingHoursWednesdayEnd'}
                                                                label={'End Time'}
                                                                fieldType={'time'}
                                                                touched={touched.operatingHoursWednesdayEnd}
                                                                error={errors.operatingHoursWednesdayEnd}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row key={'od-row-4'} className={'mb-3 p-3'}>
                                                        <Col xs={12} md={4} key={'od-switch-4'}>
                                                            <FormCheck
                                                                type='switch'
                                                                name='operatingDays'
                                                                label={'Thursday'}
                                                                onChange={(event) => {
                                                                    setShowTimes4(event.target.checked)
                                                                    handleChange(event)
                                                                }}
                                                                defaultChecked={currentProviderProfile.operatingDays.includes('4')}
                                                                value={'4'}
                                                            />
                                                        </Col>
                                                        <Col xs={6} md={4} key={'od-hour-start-4'}
                                                             id={'od-hour-start-4'} hidden={!showTimes4}>
                                                            <FormField
                                                                name={'operatingHoursThursdayStart'}
                                                                label={'Start Time'}
                                                                fieldType={'time'}
                                                                touched={touched.operatingHoursThursdayStart}
                                                                error={errors.operatingHoursThursdayStart}
                                                            />
                                                        </Col>
                                                        <Col xs={6} md={4} key={'od-hour-end-4'} id={'od-hour-end-4'}
                                                             hidden={!showTimes4}>
                                                            <FormField
                                                                name={'operatingHoursThursdayEnd'}
                                                                label={'End Time'}
                                                                fieldType={'time'}
                                                                touched={touched.operatingHoursThursdayEnd}
                                                                error={errors.operatingHoursThursdayEnd}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row key={'od-row-5'} className={'bg-white mb-3 p-3'}>
                                                        <Col xs={12} md={4} key={'od-switch-5'}>
                                                            <FormCheck
                                                                type='switch'
                                                                name='operatingDays'
                                                                label={'Friday'}
                                                                onChange={(event) => {
                                                                    setShowTimes5(event.target.checked)
                                                                    handleChange(event)
                                                                }}
                                                                defaultChecked={currentProviderProfile.operatingDays.includes('5')}
                                                                value={'5'}
                                                            />
                                                        </Col>
                                                        <Col xs={6} md={4} key={'od-hour-start-5'}
                                                             id={'od-hour-start-5'} hidden={!showTimes5}>
                                                            <FormField
                                                                name={'operatingHoursFridayStart'}
                                                                label={'Start Time'}
                                                                fieldType={'time'}
                                                                touched={touched.operatingHoursFridayStart}
                                                                error={errors.operatingHoursFridayStart}
                                                            />
                                                        </Col>
                                                        <Col xs={6} md={4} key={'od-hour-end-5'} id={'od-hour-end-5'}
                                                             hidden={!showTimes5}>
                                                            <FormField
                                                                name={'operatingHoursFridayEnd'}
                                                                label={'End Time'}
                                                                fieldType={'time'}
                                                                touched={touched.operatingHoursFridayEnd}
                                                                error={errors.operatingHoursFridayEnd}
                                                            />
                                                        </Col>
                                                    </Row>
                                                    <Row key={'od-row-6'} className={'mb-3 p-3'}>
                                                        <Col xs={12} md={4} key={'od-switch-6'}>
                                                            <FormCheck
                                                                type='switch'
                                                                name='operatingDays'
                                                                label={'Saturday'}
                                                                onChange={(event) => {
                                                                    setShowTimes6(event.target.checked)
                                                                    handleChange(event)
                                                                }}
                                                                defaultChecked={currentProviderProfile.operatingDays.includes('6')}
                                                                value={'6'}
                                                            />
                                                        </Col>
                                                        <Col xs={6} md={4} key={'od-hour-start-6'}
                                                             id={'od-hour-start-6'} hidden={!showTimes6}>
                                                            <FormField
                                                                name={'operatingHoursSaturdayStart'}
                                                                label={'Start Time'}
                                                                fieldType={'time'}
                                                                required={showTimes6}
                                                                touched={touched.operatingHoursSaturdayStart}
                                                                error={errors.operatingHoursSaturdayStart}
                                                            />
                                                        </Col>
                                                        <Col xs={6} md={4} key={'od-hour-end-6'} id={'od-hour-end-6'}
                                                             hidden={!showTimes6}>
                                                            <FormField
                                                                name={'operatingHoursSaturdayEnd'}
                                                                label={'End Time'}
                                                                fieldType={'time'}
                                                                required={showTimes6}
                                                                touched={touched.operatingHoursSaturdayEnd}
                                                                error={errors.operatingHoursSaturdayEnd}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </fieldset>
                                            </Tab>
                                            <Tab eventKey='confirm' title='Confirm' disabled={!confirmEnabled}>
                                                <fieldset className={'row m-4'}>
                                                    <legend>Confirm</legend>
                                                    <Row className='m-1'>
                                                        <Col
                                                            className='border border-2 bg-surface-secondary h-full border-dashed rounded-3 d-flex flex-column p-5'
                                                        >
                                                            <Row>
                                                                <Col>
                                                                    <fieldset className={'row m-4'}>
                                                                        <legend>General</legend>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>License Name</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.licenseName || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>License Number</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.licenseNumber || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>Program Name</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.programName || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>License Type</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.licenseType || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={12} md={3} className={'p-2'}>
                                                                            <FormLabel>Program Description</FormLabel>
                                                                        </Col>
                                                                        <Col xs={12} md={9} className={'p-2'}>
                                                                            {values.programDescription || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={12} md={3} className={'p-2'}>
                                                                            <FormLabel>Address</FormLabel>
                                                                        </Col>
                                                                        <Col xs={12} md={9} className={'p-2'}>
                                                                            {values.address || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>Contact First Name</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.contactFirstName || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>Contact Last Name</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.contactLastName || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>Contact Phone Number</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.contactPhone || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>Contact Email</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.contactEmail || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>Director Name</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.directorName || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>School Website</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.website || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>Participation Levels</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.participationLevels || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>Ages Served</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.agesServed || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>Quality Rating Finder</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.qualityRating || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>Quality Rating (Tuition
                                                                                Credit)</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.qualityRatingTuitionCredit || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>Financial Assistance
                                                                                Available</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {values.financialAssistanceAvailable || 'N/A'}
                                                                        </Col>
                                                                        <Col xs={12} md={6} className={'p-2'}/>
                                                                        <Col xs={12} md={6} className={'p-2'}>
                                                                            <Row>
                                                                                <FormLabel
                                                                                    htmlFor={'curriculum'}>Curriculum:</FormLabel>
                                                                                {Object.keys(currentProviderProfile.options.curriculum).map((curricula) => {
                                                                                    return (
                                                                                        <Col xs={12} md={6}
                                                                                             key={'curriculum-col-' + curricula}>
                                                                                            <FormCheck
                                                                                                type='switch'
                                                                                                name='curriculum'
                                                                                                label={currentProviderProfile.options.curriculum[curricula]}
                                                                                                defaultChecked={values.curriculum.includes(curricula)}
                                                                                                value={curricula}
                                                                                                key={'curriculum-' + curricula}
                                                                                                readOnly
                                                                                                disabled
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                })}
                                                                            </Row>
                                                                        </Col>
                                                                        <Col xs={12} md={6} className={'p-2'}>
                                                                            <Row>
                                                                                <FormLabel htmlFor={'languagesSpoken'}>
                                                                                    Languages Spoken:
                                                                                </FormLabel>
                                                                                {Object.keys(currentProviderProfile.options.languagesSpoken).map((lang) => {
                                                                                    return (
                                                                                        <Col xs={12} md={6}
                                                                                             key={'languagesSpoken-col-' + lang}>
                                                                                            <FormCheck
                                                                                                type='switch'
                                                                                                name='languagesSpoken'
                                                                                                label={currentProviderProfile.options.languagesSpoken[lang]}
                                                                                                defaultChecked={values.languagesSpoken.includes(lang)}
                                                                                                value={lang}
                                                                                                key={'languagesSpoken-' + lang}
                                                                                                readOnly
                                                                                                disabled
                                                                                            />
                                                                                        </Col>
                                                                                    )
                                                                                })}
                                                                            </Row>
                                                                        </Col>
                                                                    </fieldset>
                                                                    <fieldset className={'row m-4'} role="group">
                                                                        <legend>Operating Schedule</legend>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            <FormLabel>Program Season</FormLabel>
                                                                        </Col>
                                                                        <Col xs={6} md={3} className={'p-2'}>
                                                                            {currentProviderProfile.options.programSeason[values.programSeason] || 'N/A'}
                                                                        </Col>
                                                                        <Row key={'od-row-0'} className={'mb-3 p-3'}>
                                                                            <Col xs={12} md={4} key={'od-switch-0'}>
                                                                                <FormCheck
                                                                                    type='switch'
                                                                                    name='operatingDays'
                                                                                    label={'Sunday'}
                                                                                    defaultChecked={values.operatingDays.includes('0')}
                                                                                    value={'0'}
                                                                                    readOnly
                                                                                    disabled
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                xs={6}
                                                                                md={4}
                                                                                key={'od-hour-start-0'}
                                                                                hidden={!values.operatingDays.includes('0')}
                                                                            >
                                                                                {values.operatingHoursSundayStart || 'N/A'}
                                                                            </Col>
                                                                            <Col
                                                                                xs={6}
                                                                                md={4}
                                                                                key={'od-hour-end-0'}
                                                                                hidden={!values.operatingDays.includes('0')}
                                                                            >
                                                                                {values.operatingHoursSundayEnd || 'N/A'}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row key={'od-row-1'}
                                                                             className={'bg-white mb-3 p-3'}>
                                                                            <Col xs={12} md={4} key={'od-switch-1'}>
                                                                                <FormCheck
                                                                                    type='switch'
                                                                                    name='operatingDays'
                                                                                    label={'Monday'}
                                                                                    defaultChecked={values.operatingDays.includes('1')}
                                                                                    value={'1'}
                                                                                    readOnly
                                                                                    disabled
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                xs={6}
                                                                                md={4}
                                                                                key={'od-hour-start-1'}
                                                                                hidden={!values.operatingDays.includes('1')}
                                                                            >
                                                                                {values.operatingHoursMondayStart || 'N/A'}
                                                                            </Col>
                                                                            <Col
                                                                                xs={6}
                                                                                md={4}
                                                                                key={'od-hour-end-1'}
                                                                                hidden={!values.operatingDays.includes('1')}
                                                                            >
                                                                                {values.operatingHoursMondayEnd || 'N/A'}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row key={'od-row-2'} className={'mb-3 p-3'}>
                                                                            <Col xs={12} md={4} key={'od-switch-2'}>
                                                                                <FormCheck
                                                                                    type='switch'
                                                                                    name='operatingDays'
                                                                                    label={'Tuesday'}
                                                                                    defaultChecked={values.operatingDays.includes('2')}
                                                                                    value={'2'}
                                                                                    readOnly
                                                                                    disabled
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                xs={6}
                                                                                md={4}
                                                                                key={'od-hour-start-2'}
                                                                                hidden={!values.operatingDays.includes('2')}
                                                                            >
                                                                                {values.operatingHoursTuesdayStart || 'N/A'}
                                                                            </Col>
                                                                            <Col
                                                                                xs={6}
                                                                                md={4}
                                                                                key={'od-hour-end-2'}
                                                                                hidden={!values.operatingDays.includes('2')}
                                                                            >
                                                                                {values.operatingHoursTuesdayEnd || 'N/A'}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row key={'od-row-3'}
                                                                             className={'bg-white mb-3 p-3'}>
                                                                            <Col xs={12} md={4} key={'od-switch-3'}>
                                                                                <FormCheck
                                                                                    type='switch'
                                                                                    name='operatingDays'
                                                                                    label={'Wednesday'}
                                                                                    defaultChecked={values.operatingDays.includes('3')}
                                                                                    value={'3'}
                                                                                    readOnly
                                                                                    disabled
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                xs={6}
                                                                                md={4}
                                                                                key={'od-hour-start-3'}
                                                                                hidden={!values.operatingDays.includes('3')}
                                                                            >
                                                                                {values.operatingHoursWednesdayStart || 'N/A'}
                                                                            </Col>
                                                                            <Col
                                                                                xs={6}
                                                                                md={4}
                                                                                key={'od-hour-end-3'}
                                                                                hidden={!values.operatingDays.includes('3')}
                                                                            >
                                                                                {values.operatingHoursWednesdayEnd || 'N/A'}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row key={'od-row-4'} className={'mb-3 p-3'}>
                                                                            <Col xs={12} md={4} key={'od-switch-4'}>
                                                                                <FormCheck
                                                                                    type='switch'
                                                                                    name='operatingDays'
                                                                                    label={'Thursday'}
                                                                                    defaultChecked={values.operatingDays.includes('4')}
                                                                                    value={'4'}
                                                                                    readOnly
                                                                                    disabled
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                xs={6}
                                                                                md={4}
                                                                                key={'od-hour-start-4'}
                                                                                hidden={!values.operatingDays.includes('4')}
                                                                            >
                                                                                {values.operatingHoursThursdayStart || 'N/A'}
                                                                            </Col>
                                                                            <Col
                                                                                xs={6}
                                                                                md={4}
                                                                                key={'od-hour-end-4'}
                                                                                hidden={!values.operatingDays.includes('4')}
                                                                            >
                                                                                {values.operatingHoursThursdayEnd || 'N/A'}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row key={'od-row-5'}
                                                                             className={'bg-white mb-3 p-3'}>
                                                                            <Col xs={12} md={4} key={'od-switch-5'}>
                                                                                <FormCheck
                                                                                    type='switch'
                                                                                    name='operatingDays'
                                                                                    label={'Friday'}
                                                                                    defaultChecked={values.operatingDays.includes('5')}
                                                                                    value={'5'}
                                                                                    readOnly
                                                                                    disabled
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                xs={6}
                                                                                md={4}
                                                                                key={'od-hour-start-5'}
                                                                                hidden={!values.operatingDays.includes('5')}
                                                                            >
                                                                                {values.operatingHoursFridayStart || 'N/A'}
                                                                            </Col>
                                                                            <Col
                                                                                xs={6}
                                                                                md={4}
                                                                                key={'od-hour-end-5'}
                                                                                hidden={!values.operatingDays.includes('5')}
                                                                            >
                                                                                {values.operatingHoursFridayEnd || 'N/A'}
                                                                            </Col>
                                                                        </Row>
                                                                        <Row key={'od-row-6'} className={'mb-3 p-3'}>
                                                                            <Col xs={12} md={4} key={'od-switch-6'}>
                                                                                <FormCheck
                                                                                    type='switch'
                                                                                    name='operatingDays'
                                                                                    label={'Saturday'}
                                                                                    defaultChecked={values.operatingDays.includes('6')}
                                                                                    value={'6'}
                                                                                    readOnly
                                                                                    disabled
                                                                                />
                                                                            </Col>
                                                                            <Col
                                                                                xs={6}
                                                                                md={4}
                                                                                key={'od-hour-start-6'}
                                                                                hidden={!values.operatingDays.includes('6')}
                                                                            >
                                                                                {values.operatingHoursSaturdayStart || 'N/A'}
                                                                            </Col>
                                                                            <Col
                                                                                xs={6}
                                                                                md={4}
                                                                                key={'od-hour-end-6'}
                                                                                hidden={!values.operatingDays.includes('6')}
                                                                            >
                                                                                {values.operatingHoursSaturdayEnd || 'N/A'}
                                                                            </Col>
                                                                        </Row>
                                                                    </fieldset>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </fieldset>
                                            </Tab>
                                        </Tabs>
                                        <Row className={'m-md-1'}>
                                            <Col
                                                className='h-full d-flex flex-column p-5'>
                                                <Row className={'text-center'}>
                                                    <Col
                                                        className={'text-start' + ((activeTab === 'instructions') ? ' d-none' : '')}>
                                                        <Button
                                                            variant={'primary'}
                                                            onClick={() => validateForm().then(() => {
                                                                setTouched(setNestedObjectValues(errors, true))
                                                                previousStep()
                                                            })}
                                                            disabled={isInitialValid && !isValid}
                                                        >
                                                                <span className='pe-2'>
                                                                    <i className='bi bi-arrow-left'/>
                                                                </span>
                                                            <span>Previous</span>
                                                        </Button>
                                                    </Col>
                                                    <Col
                                                        className={'text-end' + ((activeTab === 'confirm') ? ' d-none' : '')}>
                                                        <Button
                                                            variant={'primary'}
                                                            className={'d-inline-flex'}
                                                            onClick={() => validateForm().then(() => {
                                                                setTouched(setNestedObjectValues(errors, true))
                                                                nextStep()
                                                            })}
                                                            disabled={!isValid}
                                                        >
                                                            <span>
                                                                {(activeTab === 'instructions') ? ' Confirm Provider Profile' : 'Proceed, this information is correct'}
                                                            </span>
                                                            <span className='ps-2'>
                                                                <i className='bi bi-arrow-right'/>
                                                            </span>
                                                        </Button>
                                                    </Col>
                                                    <Col
                                                        className={'text-end' + ((activeTab !== 'confirm') ? ' d-none' : '')}>
                                                        <Button
                                                            type={'submit'}
                                                            variant={'success'}
                                                            className={'d-inline-flex'}
                                                            disabled={!isValid}
                                                        >
                                                            <span className='pe-2'>
                                                                <i className={'bi bi-' + (dirty ? 'save' : 'check')}/>
                                                            </span>
                                                            <span>{dirty ? 'Update My Provider Profile' : 'Confirm My Provider Profile'}</span>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <ToastContainer className='p-3' position='bottom-end'>
                <Toast onClose={() => setShowToast(false)} show={showToast} bg={toastVariant} delay={7000} autohide>
                    <Toast.Header closeButton>
                        <strong className='me-auto'>System Message</strong>
                    </Toast.Header>
                    <Toast.Body className='text-white'>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </div>
    );
}

export default ProviderProfile

const container = document.getElementById('provider-profile')
if (container) {
    const root = createRoot(container)
    root.render(<ProviderProfile/>)
}
