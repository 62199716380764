import axios from 'axios'
import React, {useState} from 'react'
import {Button, Col, Container, Form, Row, Toast, ToastContainer} from 'react-bootstrap'
import {Formik} from 'formik'
import * as Yup from 'yup'
import FloatingFormField from './partial/FloatingFormField'
import {createRoot} from 'react-dom/client'

function Profile() {
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState(null)
    const [toastVariant, setToastVariant] = useState('success')
    const updateProfileURL = '/api/v1/profile/update'
    const validateSchema = Yup.object().shape({
        currentPassword: Yup.string().required('Current Password is required'),
        password: Yup.string().min(8, 'New Password must be at least 8 characters').required('New Password is required'),
        password_confirmation: Yup.string()
            .required('Confirm New Password is required')
            .oneOf([Yup.ref('password'), null], 'New Password and Confirm New Password must match')
    })
    const initialValues = {
        currentPassword: '',
        password: '',
        password_confirmation: '',
    }

    const ProfileForm = (props) => {
        const {initialValues, validateSchema, onSubmit} = props;
        return (
            <Formik
                enableReinitialize={false}
                initialValues={initialValues}
                validationSchema={validateSchema}
                onSubmit={onSubmit}
            >
                {({errors, values, touched, setValues, isValid, dirty, handleChange, handleSubmit}) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className={'m-md-1'}>
                            <Col
                                className='border border-2 bg-surface-secondary h-full border-dashed rounded-3 d-flex flex-column p-5'>
                                <Row className={'text-center'}>
                                    <Col className={'d-none d-sm-block'}/>
                                    <Col className={'text-nowrap'}>
                                        <h3>
                                            Change Password <i
                                            className="bi bi-unlock-fill avatar avatar-lg text-success"/>
                                        </h3>
                                    </Col>
                                    <Col className="text-end ">
                                        <div className="mx-n1">
                                            <Button
                                                className={'btn ' + ((isValid && dirty) ? 'btn-success' : 'btn-primary') + ' d-inline-flex mx-1'}
                                                onClick={handleSubmit}
                                            >
                                                    <span className=" pe-2">
                                                        <i className="bi bi-save"/>
                                                    </span>
                                                <span>Save</span>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                                <Row
                                    className={'p-2 align-self-center w-md-full w-sm-full w-lg-full w-xl-full w-xxl-1/2'}>
                                    <Col xs={12} md={12} className={'p-2'}>
                                        <FloatingFormField
                                            name={'currentPassword'}
                                            label={'Current Password'}
                                            fieldType={'password'}
                                            type={'control'}
                                            touched={touched.currentPassword}
                                            error={errors.currentPassword}
                                        />
                                    </Col>
                                    <Col xs={12} md={12} className={'p-2'}>
                                        <FloatingFormField
                                            name={'password'}
                                            label={'New Password'}
                                            fieldType={'password'}
                                            type={'control'}
                                            touched={touched.password}
                                            error={errors.password}
                                        />
                                    </Col>
                                    <Col xs={12} md={12} className={'p-2'}>
                                        <FloatingFormField
                                            name={'password_confirmation'}
                                            label={'Confirm New Password'}
                                            fieldType={'password'}
                                            type={'control'}
                                            touched={touched.password_confirmation}
                                            error={errors.password_confirmation}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        )
    }

    const handleModifyProfile = (fields) => {
        // console.log('updateProfileURL', fields)
        axios.post(updateProfileURL, fields).then((response) => {
            console.log(response)
            if (response.data.success) {
                setToastMessage(response.data.message)
                setToastVariant('success')
            } else {
                setToastMessage(response.data.message)
                setToastVariant('warning')
            }
            setShowToast(true)
        }).catch(error => {
            if (error.response) {
                // @todo process error.response.data.errors
                // setToastMessage(error.response.data.message)
                setToastMessage('Current Password was incorrect.')
                setToastVariant('danger')
                setShowToast(true)
                console.log(error.response);
            }
        });
    }

    return (
        <Container fluid={true}>
            <Row>
                <ProfileForm
                    validateSchema={validateSchema}
                    initialValues={initialValues}
                    onSubmit={handleModifyProfile}
                />
            </Row>
            <ToastContainer className="p-3" position='bottom-end'>
                <Toast onClose={() => setShowToast(false)} show={showToast} bg={toastVariant} delay={7000} autohide>
                    <Toast.Header closeButton>
                        <strong className="me-auto">System Message</strong>
                    </Toast.Header>
                    <Toast.Body className='text-white'>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </Container>
    );
}

export default Profile

const container = document.getElementById('profile')
if (container) {
    const root = createRoot(container)
    root.render(<Profile/>)
}
