import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {createRoot} from 'react-dom/client'
import parse from 'html-react-parser'
import {Button, Card, Col, Container, Row, Toast, ToastContainer} from 'react-bootstrap'

const contentURL = '/api/v1/content'


function Dashboard() {
    const [sessionData, setSessionData] = useState(null)
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState(null)
    const [toastVariant, setToastVariant] = useState('success')
    const [links, setLinks] = useState(null)
    const [bulletins, setBulletins] = useState(null)

    const RenderBulletins = () => {
        if (!bulletins) return null
        return (
            <Row>
                {bulletins.map(function (bulletin) {
                    return (
                        <Col key={bulletin.id} sm={12} className='rounded-3 mb-4 bg-white p-2'>
                            {bulletin.label &&
                                <h3 className='text-center'>{bulletin.label}</h3>}
                            {parse(bulletin.content)}
                        </Col>)
                })}
            </Row>
        )
    }

    const RenderLinks = () => {
        if (!links) return null
        return (<Row>
            <Col sm={12}
                 className='rounded-3 mb-4 bg-white p-2'>
                <Row>
                    <Col sm={12}>
                        <h3>Resources</h3>
                    </Col>
                    {links.map(function (link) {
                        return (<Col sm={6} key={link.id}>
                            <a href={link.link} target='_blank' className={'text-underline'}>{link.label}</a>
                        </Col>);
                    })}
                </Row>
            </Col>
        </Row>)
    }
    useEffect(() => {
        axios.get(contentURL).then((response) => {
            setBulletins(response.data.bulletin)
            setLinks(response.data.link)
        })
    }, [])

    useEffect(() => {
        loadSessionData()
    }, [])


    const sessionDataURL = '/api/v1/session-data'
    const loadSessionData = () => {
        axios.get(sessionDataURL).then((response) => {
            if (response.data.success) {
                setSessionData(true)
            } else {
                if (response.data.message && response.data.message.length > 0) {
                    setToastMessage(response.data.message)
                } else {
                    setToastMessage('Unknown error loading session data')
                }
                setToastVariant('warning')
                setShowToast(true)
            }
        })
    }

    const RenderBase = () => {
        return (<Container fluid={true}>
            <ToastContainer className='p-3 mb-5' position='bottom-end'>
                <Toast onClose={() => setShowToast(false)} show={showToast} bg={toastVariant} delay={7000} autohide>
                    <Toast.Header closeButton>
                        <strong className='me-auto'>System Message</strong>
                    </Toast.Header>
                    <Toast.Body className='text-white'>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </Container>)
    }

    if (!sessionData) {
        return <RenderBase/>
    }
    return (
        <Container fluid={true}>
            <RenderLinks/>
            <RenderBulletins/>
            <Row className='row-cols-1 row-cols-md-3 g-4'>
                <Col>
                    <Card className='h-full'>
                        <Card.Header>
                            <Card.Link href='/roster'><h2 className='text-center'>Manage Roster</h2></Card.Link>
                        </Card.Header>
                        <Card.Body>
                            <Card.Link href='/roster' className={'text-black'}>
                                Add, review, and enroll students who qualify for support to your roster.
                            </Card.Link>
                        </Card.Body>
                        <Card.Footer className='text-center'>
                            <Card.Link href='/roster'>
                                <Button className='primary'>
                                    <i className="bi bi-person-check me-2"></i>Manage Roster
                                </Button>
                            </Card.Link>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card className='h-full'>
                        <Card.Header>
                            <Card.Link href='/attendance'><h2 className='text-center'>Submit Attendance</h2></Card.Link>
                        </Card.Header>
                        <Card.Body>
                            <Card.Link href='/attendance' className={'text-black'}>Submit attendance records for your
                                students.</Card.Link>
                        </Card.Body>
                        <Card.Footer className='text-center'>
                            <Card.Link href='/attendance'>
                                <Button className='primary'>
                                    <i className="bi bi-list-check me-2"></i>Submit Attendance
                                </Button>
                            </Card.Link>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card className='h-full'>
                        <Card.Header>
                            <Card.Link href='/update-availability'>
                                <h2 className='text-center'>Update Enrollment Availability</h2>
                            </Card.Link>
                        </Card.Header>
                        <Card.Body>
                            <Card.Link href='/update-availability' className={'text-black'}>
                                Here you can update the number of available enrollment spots at your site.
                            </Card.Link>
                        </Card.Body>
                        <Card.Footer className='text-center'>
                            <Card.Link href='/update-availability'>
                                <Button className='primary'>
                                    <i className="bi bi-card-checklist me-2"></i>Update Enrollment Availability
                                </Button>
                            </Card.Link>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card className='h-full'>
                        <Card.Header>
                            <Card.Link href='/provider-profile'>
                                <h2 className='text-center'>Provider Profile</h2>
                            </Card.Link>
                        </Card.Header>
                        <Card.Body>
                            <Card.Link href='/provider-profile' className={'text-black'}>
                                Here you can update the number of available enrollment spots at your site along with
                                additional information available on the new Find-a-Preschool tool.
                            </Card.Link>
                        </Card.Body>
                        <Card.Footer className='text-center'>
                            <Card.Link href='/provider-profile'>
                                <Button className='primary'>
                                    <i className="bi bi-card-checklist me-2"></i>Provider Profile
                                </Button>
                            </Card.Link>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card className='h-full'>
                        <Card.Header>
                            <Card.Link href='/payments'><h2 className='text-center'>Payment Reports</h2></Card.Link>
                        </Card.Header>
                        <Card.Body>
                            <Card.Link href='/payments' className={'text-black'}>Review prior tuition credit
                                payments</Card.Link>
                        </Card.Body>
                        <Card.Footer className='text-center'>
                            <Card.Link href='/payments'>
                                <Button className='primary'>
                                    <i className="bi bi-cash-coin me-2"></i>Payment Reports
                                </Button>
                            </Card.Link>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Dashboard;

const container = document.getElementById('dashboard')
if (container) {
    const root = createRoot(container)
    root.render(<Dashboard/>)
}
