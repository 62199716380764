import axios from 'axios'
import React, {useEffect, useState} from 'react'
import {
    Alert,
    Button,
    Col,
    Container,
    Form,
    FormGroup,
    FormLabel,
    FormSelect,
    Modal,
    Row,
    Table,
    Toast,
    ToastContainer
} from 'react-bootstrap'
import {ErrorMessage, Field, Formik} from 'formik'
import * as Yup from 'yup'
import FloatingFormField from './partial/FloatingFormField'
import {createRoot} from 'react-dom/client'

function Roster() {
    const [programYears, setProgramYears] = useState(null)
    const [currentProgramYear, setCurrentProgramYear] = useState(null)
    const [currentStudent, setCurrentStudent] = useState(null)
    const [editModalShow, setEditModalShow] = useState(false)
    const [modalShow, setModalShow] = useState(false)
    const [roster, setRoster] = useState(null)
    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState(null)
    const [toastVariant, setToastVariant] = useState('success')
    const [viewModalShow, setViewModalShow] = useState(false)
    useEffect(() => {
        axios.get(programYearsURL).then((response) => {
            setCurrentProgramYear(response.data.data[0].program_year_id)
            setProgramYears(response.data.data)
            getRosterData(response.data.data[0].program_year_id)
        })
    }, [])

    const getRosterData = (py) => {
        axios.post(rosterURL, {'programYear': py}).then((response) => {
            setRoster(response.data.data)
        })
    }
    const rosterURL = '/api/v1/roster'
    const rosterAddURL = '/api/v1/roster/add'
    const rosterModifyURL = '/api/v1/roster/update'
    const programYearsURL = '/api/v1/program-years/all'
    const today = new Date();
    today.setHours(0, 0, 0, 0)
    const addStudentValidateSchema = Yup.object().shape({
        studentFirstName: Yup.string().required('Entry required'),
        studentLastName: Yup.string().required('Entry required'),
        studentDob: Yup.date()
            .required('Please enter a Student Date of Birth')
            .max(today, 'Student Date of Birth cannot be today or later'),
        startDate: Yup.date()
            .required('Please enter a Start Date'),
        participationType: Yup.string().required('Selection required'),
        parentFirstName: Yup.string().required('Entry required'),
        parentLastName: Yup.string().required('Entry required'),
        parentEmail: Yup.string().email().required('Entry required'),
        parentPhone: Yup.string().min(10, 'When entered at least 10 digits are required').required('Entry required')
    })
    const modifyStudentValidateSchema = Yup.object().shape({
        startDate: Yup.date()
            .nullable()
            .notRequired()
            .min(Yup.ref('approvalDate'), 'Must be on or after the Application Approval Date')
            .when(['endDate', 'currentEndDate', 'enrollmentStatus'], (endDate, currentEndDate, enrollmentStatus, schema) => {
                if (enrollmentStatus !== 'inactive') {
                    if (endDate) {
                        const ed = new Date(endDate)
                        ed.setDate(ed.getDate() - 1)
                        return schema.max(ed, 'Must be before the requested Enrollment End Date')
                    }
                    if (currentEndDate) {
                        const ced = new Date(currentEndDate)
                        ced.setDate(ced.getDate() - 1)
                        return schema.max(ced, 'Must be before the current Enrollment End Date')
                    }
                }
                return schema
            }),
        endDate: Yup.date()
            .nullable()
            .notRequired()
            .when(['startDate', 'currentStartDate', 'approvalDate', 'enrollmentStatus'], (startDate, currentStartDate, approvalDate, enrollmentStatus, schema) => {
                if (enrollmentStatus !== 'inactive') {
                    if (startDate) {
                        const sd = new Date(startDate)
                        sd.setDate(sd.getDate() + 1)
                        return schema.min(sd, 'Must be after the requested Enrollment Start Date')
                    }
                    if (currentStartDate) {
                        const csd = new Date(currentStartDate)
                        csd.setDate(csd.getDate() + 1)
                        return schema.min(csd, 'Must be after the current Enrollment Start Date')
                    }
                } else {
                    return schema.min(approvalDate, 'Must be on or after the Application Approval Date')
                }
            }),
        participationTypeChangeDate: Yup.date()
            .nullable()
            .min(Yup.ref('approvalDate'), 'Must be on or after the Application Approval Date')
            .when('enableParticipationTypeChangeDate', (enableParticipationTypeChangeDate, schema) => {
                if (enableParticipationTypeChangeDate) {
                    return schema.required('Entry required')
                }
            }),
        participationType: Yup.string().nullable().notRequired(),
        enableParticipationTypeChangeDate: Yup.boolean(),
        approvalDate: Yup.date(),
        currentStartDate: Yup.date(),
        currentEndDate: Yup.date(),
        enrollmentStatus: Yup.string(),
    }, [
        ['participationTypeChangeDate', 'approvalDate', 'enableParticipationTypeChangeDate'],
        ['endDate', 'startDate', 'currentStartDate', 'approvalDate', 'enrollmentStatus'],
        ['startDate', 'endDate', 'approvalDate', 'currentEndDate', 'enrollmentStatus']
    ])
    const addStudentInitialValues = {
        studentFirstName: '',
        studentLastName: '',
        studentDob: '',
        startDate: '',
        participationType: '',
        parentFirstName: '',
        parentLastName: '',
        parentEmail: '',
        parentPhone: ''
    }

    const AddStudentModal = (props) => {
        const {initialValues, validateSchema, ...modalProps} = props;
        return (
            <Modal
                {...modalProps}
                size={'lg'}
                aria-labelledby={'contained-modal-title-vcenter'}
                centered
            >
                <Formik
                    validationSchema={validateSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        props.onSubmit(values, props.onHide)
                    }}
                >
                    {({
                          errors,
                          touched,
                          isValid,
                          dirty,
                          handleSubmit,
                          isSubmitting
                      }) => (
                        <Form noValidate>
                            <Modal.Header closeButton>
                                <Modal.Title id={'contained-modal-title-vcenter'}>
                                    Add Student
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className={'show-grid'}>
                                <Container>
                                    <Row className='m-1'>
                                        <Col
                                            className={'border border-2 bg-surface-secondary h-full border-dashed rounded-3 d-flex flex-column p-5'}
                                        >
                                            Complete the following fields for each student you believe to be eligible.
                                            We must have the student name, participation type, DOB and start date to be
                                            able to review the family's eligibility. Once you have completed the
                                            information, click the "Save" button to your request. You may click the
                                            "Close" button to if you no longer wish to add another student.
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <FloatingFormField
                                                name={'studentFirstName'}
                                                label={'Student First Name'}
                                                type={'control'}
                                                touched={touched.studentFirstName}
                                                error={errors.studentFirstName}
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatingFormField
                                                name={'studentLastName'}
                                                label={'Student Last Name'}
                                                type={'control'}
                                                touched={touched.studentLastName}
                                                error={errors.studentLastName}
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatingFormField
                                                name={'studentDob'}
                                                label={'Student Date of Birth'}
                                                type={'control'}
                                                fieldType={'date'}
                                                touched={touched.studentDob}
                                                error={errors.studentDob}
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FormGroup className={'form-floating mb-1'} controlId="participationType">
                                                <Field
                                                    as={'select'}
                                                    name="participationType"
                                                    className={'form-select form-select-sm' + (touched.participationType ? (errors.participationType ? ' is-invalid' : ' is-valid') : '')}
                                                >
                                                    <option value={''}/>
                                                    <option value='part_day'>Half Day (5-24 hrs/wk)</option>
                                                    <option value='full_day'>Full Day (25-32 hrs/wk)</option>
                                                    <option value='extended_day'>Extended Day (33+ hrs/wk)</option>
                                                </Field>
                                                <FormLabel>
                                                    Participation Type
                                                </FormLabel>
                                                <ErrorMessage
                                                    name="participationType"
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatingFormField
                                                name={'startDate'}
                                                label={'Start Date'}
                                                type={'control'}
                                                fieldType={'date'}
                                                touched={touched.startDate}
                                                error={errors.startDate}
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatingFormField
                                                name={'parentFirstName'}
                                                label={'Parent/Guardian First Name'}
                                                type={'control'}
                                                touched={touched.parentFirstName}
                                                error={errors.parentFirstName}
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatingFormField
                                                name={'parentLastName'}
                                                label={'Parent/Guardian Last Name'}
                                                type={'control'}
                                                touched={touched.parentLastName}
                                                error={errors.parentLastName}
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatingFormField
                                                name={'parentEmail'}
                                                label={'Parent/Guardian Email'}
                                                fieldType={'email'}
                                                type={'control'}
                                                touched={touched.parentEmail}
                                                error={errors.parentEmail}
                                            />
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <FloatingFormField
                                                name={'parentPhone'}
                                                label={'Parent/Guardian Phone'}
                                                fieldType={'phone'}
                                                type={'control'}
                                                touched={touched.parentPhone}
                                                error={errors.parentPhone}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className={'btn-secondary'} onClick={props.onHide}>Close</Button>
                                <Button
                                    className={'btn d-inline-flex btn-primary mx-1' + ((dirty && isValid) && !isSubmitting ? '' : ' disabled')}
                                    onClick={handleSubmit}
                                >
                                    <span className="pe-2"><i className="bi bi-save"/></span>
                                    <span>Save</span>
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        );
    }

    const ViewStudentModal = props => {
        const {student, ...modalProps} = props
        if (!student) return null
        return (
            <Modal
                {...modalProps}
                size={'xl'}
                aria-labelledby={'contained-modal-title-vcenter'}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id={'contained-modal-title-vcenter'}>
                        {student.contact_first_name} {student.contact_last_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'show-grid m-0'}>
                    <Container fluid={true}>
                        <Row>
                            {student.application_approval_date && <>
                                <Col xs={6} xl={3} className={'text-nowrap'}>
                                    Application Approval Date:
                                </Col>
                                <Col xs={6} xl={3}>
                                    {student.application_approval_date}
                                </Col>
                            </>}
                            {student.application_status && <>
                                <Col xs={6} xl={3} className={'text-nowrap'}>
                                    Application Status:
                                </Col>
                                <Col xs={6} xl={3}>
                                    {student.application_status}
                                </Col>
                            </>}
                            {student.application_status_details && <>
                                <Col xs={6} xl={3} className={'text-nowrap'}>
                                    Application Status Details:
                                </Col>
                                <Col xs={6} xl={3}>
                                    {student.application_status_details}
                                </Col>
                            </>}
                            {student.enrollment_participation_type_display && <>
                                <Col xs={6} xl={3} className={'text-nowrap'}>
                                    Participation Type:
                                </Col>
                                <Col xs={6} xl={3}>
                                    {student.enrollment_participation_type_display}
                                </Col>
                            </>}
                            <Col xs={6} xl={3} className={'text-nowrap'}>
                                Enrollment Start Date:
                            </Col>
                            <Col xs={6} xl={3}>
                                {student.enrollment_start_date}
                            </Col>
                            {student.requested_start_date && <>
                                <Col xs={6} xl={3} className={'text-nowrap'}>
                                    Requested Enrollment Start Date:
                                </Col>
                                <Col xs={6} xl={3}>
                                    {student.requested_start_date}
                                </Col>
                            </>}
                            <Col xs={6} xl={3} className={'text-nowrap'}>
                                Enrollment End Date:
                            </Col>
                            <Col xs={6} xl={3}>
                                {student.enrollment_end_date}
                            </Col>
                            {student.requested_end_date && <>
                                <Col xs={6} xl={3} className={'text-nowrap'}>
                                    Requested Enrollment End Date:
                                </Col>
                                <Col xs={6} xl={3}>
                                    {student.requested_end_date}
                                </Col>
                            </>}
                            {student.enrollment_scholarship_status && <>
                                <Col xs={6} xl={3} className={'text-nowrap'}>
                                    Scholarship Status:
                                </Col>
                                <Col xs={6} xl={3}>
                                    {student.enrollment_scholarship_status}
                                </Col>
                            </>}
                            {student.enrollment_status_display && <>
                                <Col xs={6} xl={3} className={'text-nowrap'}>
                                    Enrollment Status:
                                </Col>
                                <Col xs={6} xl={3}>
                                    <StudentBadge
                                        status={student.enrollment_status}
                                        statusDisplay={student.enrollment_status_display}
                                    />
                                </Col>
                            </>}
                            {student.enrollment_tuition_credit_display && <>
                                <Col xs={6} xl={3} className={'text-nowrap'}>
                                    Enrollment Tuition Credit:
                                </Col>
                                <Col xs={6} xl={3}>
                                    {student.enrollment_tuition_credit_display}
                                </Col>
                            </>}
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button className={'btn-secondary'} onClick={props.onHide}>Close</Button>
                    {!student.pending && (student.enrollment_status === 'active' || student.enrollment_status === 'inactive') &&
                        <Button className={'btn-primary'} onClick={props.onSubmit}>
                            <span className=" pe-2">
                                <i className={'bi bi-pencil-square '}/>
                            </span>
                            <span>Modify</span>
                        </Button>}
                </Modal.Footer>
            </Modal>
        );
    }

    const EditStudentModal = props => {
        const {student, initialValues, ...modalProps} = props
        if (!student) return null
        const enableDisableParticipationTypeChangeDate = (initialValues, values, setFieldValue, setTouched) => {
            let setValue
            if (initialValues.participationType !== values.participationType) {
                setValue = async () => await setFieldValue('enableParticipationTypeChangeDate', true)
            } else {
                setValue = async () => await setFieldValue('enableParticipationTypeChangeDate', false)
            }
            setValue().then(() => setTouched({participationTypeChangeDate: true}, true))
        }
        return (
            <Modal
                {...modalProps}
                size={'xl'}
                aria-labelledby={'contained-modal-title-vcenter'}
                centered
            >
                <Formik
                    enableReinitialize={true}
                    validationSchema={modifyStudentValidateSchema}
                    initialValues={initialValues}
                    onSubmit={(values) => {
                        props.onSubmit(values, props.onHide)
                    }}
                >
                    {({
                          errors,
                          values,
                          touched,
                          setFieldValue,
                          isValid,
                          dirty,
                          handleSubmit,
                          isSubmitting,
                          setTouched
                      }) => (
                        <Form noValidate>
                            <Modal.Header closeButton>
                                <Modal.Title id={'contained-modal-title-vcenter'}>
                                    {student.contact_first_name} {student.contact_last_name}
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body className={'show-grid m-0'}>
                                <Container fluid={true}>
                                    <Row>
                                        {student.application_approval_date && <>
                                            <Col xs={6} xl={3} className={'text-nowrap'}>
                                                Application Approval Date:
                                            </Col>
                                            <Col xs={6} xl={3}>
                                                {student.application_approval_date}
                                            </Col>
                                        </>}
                                        {student.application_status && <>
                                            <Col xs={6} xl={3} className={'text-nowrap'}>
                                                Application Status:
                                            </Col>
                                            <Col xs={6} xl={3}>
                                                {student.application_status}
                                            </Col>
                                        </>}
                                        {student.application_status_details && <>
                                            <Col xs={6} xl={3} className={'text-nowrap'}>
                                                Application Status Details:
                                            </Col>
                                            <Col xs={6} xl={3}>
                                                {student.application_status_details}
                                            </Col>
                                        </>}
                                        <Col xs={6} xl={3} className={'text-nowrap'}>
                                            Enrollment Start Date:
                                        </Col>
                                        <Col xs={6} xl={3}>
                                            {student.enrollment_start_date}
                                        </Col>
                                        <Col xs={6} xl={3} className={'text-nowrap'}>
                                            Enrollment End Date:
                                        </Col>
                                        <Col xs={6} xl={3}>
                                            {student.enrollment_end_date}
                                        </Col>
                                        <Col xs={6} xl={3} className={'text-nowrap'}>
                                            Enrollment Status:
                                        </Col>
                                        <Col xs={6} xl={3}>
                                            <StudentBadge
                                                status={student.enrollment_status}
                                                statusDisplay={student.enrollment_status_display}
                                            />
                                        </Col>
                                        <Col xs={6} xl={3} className={'text-nowrap'}>
                                            Scholarship Status:
                                        </Col>
                                        <Col xs={6} xl={3}>
                                            {student.enrollment_scholarship_status}
                                        </Col>
                                        <Col xs={6} xl={3} className={'text-nowrap'}>
                                            Enrollment Tuition Credit:
                                        </Col>
                                        <Col xs={6} xl={3}>
                                            {student.enrollment_tuition_credit_display}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} xl={6}>
                                            <FormGroup className={'form-floating mb-1'} controlId="participationType">
                                                <Field
                                                    as={'select'}
                                                    name={'participationType'}
                                                    className={'form-select form-select-sm' + (errors.participationType ? ' is-invalid' : ' is-valid')}
                                                    onBlur={() => enableDisableParticipationTypeChangeDate(initialValues, values, setFieldValue, setTouched)}
                                                >
                                                    <option value=''>
                                                        {student.enrollment_participation_type_display ? student.enrollment_participation_type_display + ' (Current)' : 'No change'}
                                                    </option>
                                                    {student.enrollment_participation_type_display !== 'Half Day' &&
                                                        <option value='part_day'>Half Day</option>}
                                                    {student.enrollment_participation_type_display !== 'Full Day' &&
                                                        <option value='full_day'>Full Day</option>}
                                                    {student.enrollment_participation_type_display !== 'Extended Day' &&
                                                        <option value='extended_day'>Extended Day</option>}
                                                </Field>
                                                <FormLabel>
                                                    Change Participation Type
                                                </FormLabel>
                                                <ErrorMessage
                                                    name="participationType"
                                                    component="div"
                                                    className="invalid-feedback"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col xs={12} xl={6}>
                                            <FloatingFormField
                                                name={'participationTypeChangeDate'}
                                                label={'Effective Date of Participation Type Change'}
                                                type={'control'}
                                                disabled={!dirty || undefined}
                                                fieldType={'date'}
                                                touched={touched.participationTypeChangeDate}
                                                error={errors.participationTypeChangeDate}
                                            />
                                        </Col>
                                        <Col xs={12} xl={6}>
                                            <FloatingFormField
                                                name={'startDate'}
                                                label={'Change Enrollment Start Date'}
                                                type={'control'}
                                                fieldType={'date'}
                                                min={student.application_approval_date}
                                                touched={true}
                                                error={errors.startDate}
                                            />
                                        </Col>
                                        <Col xs={12} xl={6}>
                                            <FloatingFormField
                                                name={'endDate'}
                                                label={'Change Enrollment End Date'}
                                                type={'control'}
                                                fieldType={'date'}
                                                min={student.application_approval_date}
                                                touched={true}
                                                error={errors.endDate}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button className={'btn-secondary'} onClick={props.onHide}>Cancel</Button>
                                <Button
                                    className={'btn d-inline-flex btn-primary mx-1' + ((dirty && isValid) && !isSubmitting ? '' : ' disabled')}
                                    onClick={handleSubmit}
                                >
                                    <span className="pe-2"><i className="bi bi-save"/></span>
                                    <span>Save</span>
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        );
    }

    const StatusIcon = (props) => {
        switch (props.status) {
            case 'site_claim_pending':
            case 'pending':
                return <i className="avatar avatar-lg bi bi-person-fill text-warning"/>
            case 'inactive':
                return <i className="avatar avatar-lg bi bi-person-x-fill text-danger"/>
            default:
                return <i className={'avatar avatar-lg bi bi-person-check-fill text-success'}/>
        }
    }

    const StudentBadge = (props) => {
        switch (props.status) {
            case 'site_claim_pending':
            case 'pending':
                return <span className="badge bg-soft-warning text-warning">{props.statusDisplay}</span>
            case 'inactive':
                return <span className="badge bg-soft-danger text-danger">{props.statusDisplay}</span>
            default:
                return <span className="badge bg-soft-success text-success">{props.statusDisplay}</span>
        }
    }

    const handleAddStudentSubmit = (fields, closeCallback) => {
        fields.programYear = currentProgramYear
        axios.post(rosterAddURL, fields).then((response) => {
            if (response.data.success) {
                setToastMessage(response.data.message)
                setToastVariant('success')
                setRoster(response.data.data)
            } else {
                setToastMessage(response.data.message)
                setToastVariant('warning')
            }
            closeCallback()
            setShowToast(true)
        })
    }

    const handleModifyStudentSubmit = (fields, closeCallback) => {
        fields.programYear = currentProgramYear
        axios.post(rosterModifyURL, fields).then((response) => {
            if (response.data.success) {
                setToastMessage(response.data.message)
                setToastVariant('success')
                setRoster(response.data.data)
            } else {
                setToastMessage(response.data.message)
                setToastVariant('warning')
            }
            closeCallback()
            setShowToast(true)
        })
    }

    if (!roster) return null;
    return (
        <Container fluid={true}>
            <Alert variant={'success'} className={'mb-3'}>
                Should a family move to a new address or have a change in household size or income, these changes must
                be reported to us at info@dpp.org. Please note if a family moves out of Denver, they are no longer
                eligible for funding.
            </Alert>
            <Row className={'text-end'}>
                <Col xs={12} md={8} className="mb-3">
                    <FormGroup controlId="programYear" className={'d-inline-flex'}>
                        <FormLabel className={'text-nowrap p-2'}>Program Year</FormLabel>
                        <FormSelect size={'sm'} defaultValue={currentProgramYear} onChange={(e) => {
                            setCurrentProgramYear(e.target.value)
                            getRosterData(e.target.value)
                        }}>
                            {programYears.map((programYear) => {
                                return (
                                    <option key={programYear.program_year_id}
                                            value={programYear.program_year_id}>{programYear.program_year_name}</option>
                                )
                            })}
                        </FormSelect>
                    </FormGroup>
                </Col>
                <Col xs={12} md={4} className="mb-3">
                    <div>
                        <a className="btn d-inline-flex btn-primary mx-1" onClick={() => setModalShow(true)}>
                        <span className="pe-2">
                            <i className={'bi bi-person-plus-fill'}/>
                        </span>
                            <span>Add Student</span>
                        </a>
                    </div>
                </Col>
                <AddStudentModal
                    show={modalShow}
                    validateSchema={addStudentValidateSchema}
                    initialValues={addStudentInitialValues}
                    onHide={() => setModalShow(false)}
                    onSubmit={handleAddStudentSubmit}
                />
                <ViewStudentModal
                    show={viewModalShow}
                    student={currentStudent}
                    onHide={() => setViewModalShow(false)}
                    onSubmit={() => {
                        setViewModalShow(false)
                        setEditModalShow(true)
                    }}
                />
                <EditStudentModal
                    show={editModalShow}
                    student={currentStudent}
                    onHide={() => setEditModalShow(false)}
                    onSubmit={handleModifyStudentSubmit}
                    initialValues={{
                        approvalDate: currentStudent?.application_approval_date || '',
                        currentStartDate: currentStudent?.enrollment_start_date || '',
                        currentEndDate: currentStudent?.enrollment_end_date || '',
                        enableParticipationTypeChangeDate: false,
                        startDate: currentStudent?.requested_start_date || '',
                        endDate: currentStudent?.requested_end_date || '',
                        participationTypeChangeDate: '',
                        participationType: '',
                        enrollment: currentStudent?.enrollment_id,
                        enrollmentStatus: currentStudent?.enrollment_status
                    }}
                />
            </Row>
            <Table responsive={true} variant={'light'} striped className={'rounded-2 overflow-hidden table-nowrap'}>
                <thead>
                <tr>
                    <th scope={'col'} className={'w-1'}/>
                    <th scope={'col'}>Name</th>
                    <th scope={'col'}>Participation Type</th>
                    <th scope={'col'}>Enrollment Status</th>
                    <th scope={'col'}>Program Year</th>
                    <th scope={'col'} className={'text-end pt-1 pb-1'}>View / Modify</th>
                </tr>
                </thead>
                <tbody>
                {roster.roster && roster.roster.map((student) => {
                    if (student.enrollment_status === 'inactive') return null
                    return (
                        <tr key={`${student.enrollment_id}-${student.application_id}-${student.contact_id}`}
                            className={'pt-1 pb-1'}>
                            <td data-label={'Icon'} className={'pt-1 pb-1'}>
                                <StatusIcon status={student.enrollment_status}/>
                            </td>
                            <td data-label={'Name'} className={'pt-1 pb-1'}>
                                <a className={'text-heading font-semibold'}>
                                    {student.contact_first_name} {student.contact_last_name}
                                </a>
                            </td>
                            <td data-label={'Participation Type'} className={'pt-1 pb-1'}>
                                <span>{student.enrollment_participation_type_display}</span>
                            </td>
                            <td data-label={'Enrollment Status'} className={'pt-1 pb-1'}>
                                <StudentBadge status={student.enrollment_status}
                                              statusDisplay={student.enrollment_status_display}/>
                            </td>
                            <td data-label={'Enrollment Status'} className={'pt-1 pb-1'}>
                                {student.program_year}
                            </td>
                            <td data-label={'View / Modify'} className={'text-end pt-1 pb-1'}>
                                <Button className={'btn-primary btn-sm'} onClick={() => {
                                    setCurrentStudent(student);
                                    setViewModalShow(true);
                                }}>
                                    <i className={'bi bi-binoculars-fill'}/>
                                </Button>
                            </td>
                        </tr>
                    );
                })}
                {roster.pendingRoster && roster.pendingRoster.map((student) => {
                    return (
                        <tr key={`${student.pending_student_id}`}
                            className={'pt-1 pb-1'}>
                            <td data-label={'Icon'} className={'pt-1 pb-1'}>
                                <StatusIcon status={'pending'}/>
                            </td>
                            <td data-label={'Name'} className={'pt-1 pb-1'}>
                                <a className={'text-heading font-semibold'}>
                                    {student.contact_first_name} {student.contact_last_name}
                                </a>
                            </td>
                            <td data-label={'Participation Type'} className={'pt-1 pb-1'}>
                                <span>{student.enrollment_participation_type_display}</span>
                            </td>
                            <td data-label={'Enrollment Status'} className={'pt-1 pb-1'}>
                                <StudentBadge status={'pending'} statusDisplay={student.enrollment_status_display}/>
                            </td>
                            <td data-label={'Enrollment Status'} className={'pt-1 pb-1'}>
                                {student.program_year_c}
                            </td>
                            <td data-label="" className={'text-end pt-1 pb-1'}>
                                <Button className={'btn-primary btn-sm'} onClick={() => {
                                    setCurrentStudent(student);
                                    setViewModalShow(true);
                                }}>
                                    <i className={'bi bi-binoculars-fill'}/>
                                </Button>
                            </td>
                        </tr>
                    )
                })}
                {roster.roster && roster.roster.map((student) => {
                    if (student.enrollment_status !== 'inactive') return null
                    return (
                        <tr key={`${student.enrollment_id}-${student.application_id}-${student.contact_id}`}
                            className={'pt-1 pb-1'}>
                            <td data-label={'Icon'} className={'pt-1 pb-1'}>
                                <StatusIcon status={student.enrollment_status}/>
                            </td>
                            <td data-label={'Name'} className={'pt-1 pb-1'}>
                                <a className={'text-heading font-semibold'}>
                                    {student.contact_first_name} {student.contact_last_name}
                                </a>
                            </td>
                            <td data-label={'Participation Type'} className={'pt-1 pb-1'}>
                                <span>{student.enrollment_participation_type_display}</span>
                            </td>
                            <td data-label={'Enrollment Status'} className={'pt-1 pb-1'}>
                                <StudentBadge status={student.enrollment_status}
                                              statusDisplay={student.enrollment_status_display}/>
                            </td>
                            <td data-label={'Enrollment Status'} className={'pt-1 pb-1'}>
                                {student.program_year}
                            </td>
                            <td data-label="" className={'text-end pt-1 pb-1'}>
                                <Button className={'btn-primary btn-sm'} onClick={() => {
                                    setCurrentStudent(student);
                                    setViewModalShow(true);
                                }}>
                                    <i className={'bi bi-binoculars-fill'}/>
                                </Button>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </Table>
            <ToastContainer className="p-3" position='bottom-end'>
                <Toast onClose={() => setShowToast(false)} show={showToast} bg={toastVariant} delay={7000} autohide>
                    <Toast.Header closeButton>
                        <strong className="me-auto">System Message</strong>
                    </Toast.Header>
                    <Toast.Body className='text-white'>{toastMessage}</Toast.Body>
                </Toast>
            </ToastContainer>
        </Container>
    );
}

export default Roster

const container = document.getElementById('roster')
if (container) {
    const root = createRoot(container)
    root.render(<Roster/>)
}
